import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PostContractorDto } from 'src/app/dto/postContractor.dto';
import { ContractorService } from 'src/app/services/contractor/contractor.service';
import { MatDialogRef } from '@angular/material';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { LegalFormInterface } from 'src/app/interfaces/legalForm.interface';
import { DocumentTypeInterface } from 'src/app/interfaces/documentType.interface';
import { TranslateService } from '@ngx-translate/core';
import { DocumentTypeService } from 'src/app/services/documentType/documentType.service';
import { pairwise, startWith, debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-add-contractor-dialog',
  templateUrl: './add-contractor-dialog.component.html',
  styleUrls: ['./add-contractor-dialog.component.scss']
})
export class AddContractorDialogComponent implements OnInit, OnDestroy {
  // Error flags
  public errExistValue: boolean;
  public errDocumentInvalid: boolean;
  public errGeneric: boolean;

  public form: FormGroup;
  public description: string;
  public postError: boolean;
  private subscriptions: Subscription[] = [];

  public legalFormList: LegalFormInterface[] = [];
  public selectedLegalForm: LegalFormInterface = <LegalFormInterface>{};
  public validLegalForm: boolean;
  public loading: boolean;
  // notAllowedChar controla si se ha introducido un char no permitido en el campo CIF para mostrar un warning
  public notAllowedChar: boolean = false;

  public documentTypeList: DocumentTypeInterface [] = []; // Lista original de clases de documento
  public translatedDocTypesList: DocumentTypeInterface[] = []; // Lista traducida de clases de documento
  public documentIsValid: boolean; // Flag que determina si el valor del documento pasa la validación
  public showDocumentInvalidError: boolean; // Flag que determina si se debe o no mostrar el error de validación de documento

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private _contractor: ContractorService, 
    public dialogRef: MatDialogRef<AddContractorDialogComponent>, private _documentType: DocumentTypeService, 
    private translate: TranslateService) {}

  ngOnInit() {
    this.documentIsValid = false;
    this.showDocumentInvalidError = false;
    this.description = "Añadir empresa";
    this.postError = false;
    this.createForm();
    this.setCodeOrCompany(this.data.name);
    this.validLegalForm = true;
    this.loading = false;
    this.getDocumentTypes(this.translate);

    // Esto provoca que el botón de crear quede deshabilitado hasta que se compruebe el valor del Documento
    this.subscriptions.push(this.form.get('type').valueChanges.subscribe(cambio =>{ 
      this.form.get('code').reset();
    }));

    // Esto provoca que el botón de crear quede deshabilitado hasta que se compruebe el valor del Documento
    this.subscriptions.push(this.form.get('code').valueChanges.subscribe(cambio =>{ 
      this.documentIsValid = false;
    }));
    
    // Comprobamos si el Documento es válido invocando al backend
    this.subscriptions.push(this.form.get('code').valueChanges
      .pipe(debounceTime(1200), startWith(null), pairwise()).subscribe(([prevCode, actualCode]: [string, string]) => {

        // Se eliminan los espacios y nos quedamos con el código anterior y el actual (aunque sólo este último se le aplica validación)
        prevCode = prevCode ? prevCode.trim() : prevCode;
        actualCode = actualCode ? actualCode.trim() : actualCode;

        /* Validación del valor introducido en caso de ser NIF, NIE o CIF */
        let selectedType = this.form.get('type').value.code;
        if(selectedType != 'OTHER_DOCUMENT' && actualCode != null){
          this.subscriptions.push(this._documentType.getDocumentValidation(selectedType, actualCode).subscribe(validatedDocument => {
            if(validatedDocument != null && validatedDocument.isValid){
              this.documentIsValid = true;
              this.showDocumentInvalidError = false;
            }
            else { 
              // En este caso, debemos mostrar un error de validación
              this.documentIsValid = false;
              this.showDocumentInvalidError = true;
            }
          }))
        }
        else{
          this.documentIsValid = true;
          this.showDocumentInvalidError = false;
          console.log("No aplica la validación");
        }
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  /* jalapont: RMS #5400 - se modifican los campos del form */
  private createForm() {
    this.form = this.fb.group({
      type: [null, Validators.compose([Validators.required])], //Clase de documento
      code: [null, Validators.compose([Validators.required])], //CIF
      company: [null, Validators.compose([Validators.required])], //Razón social
      //legalForm: [null, Validators.compose([Validators.required])] //DS2-T14819 - Se eliminarForma jurídica
    });
  }

  // Recuperamos la lista de Clases de Documento invocando al Backend. 
  // Una vez recuperada, la traducimos creando una lista de pares code/value
  public getDocumentTypes(translate:TranslateService){
    this.documentTypeList = [];
    this.subscriptions.push(this._documentType.getAllDocumentTypes().subscribe(documentTypesListReceived => {
      documentTypesListReceived.forEach( (element) => {
        this.documentTypeList.push({code: element.code});
      });
      this.documentTypeList.forEach( (element) => {
        this.translatedDocTypesList.push({code: element.code, valueInCombo: translate.instant("DocumentTypes." + element.code)});
      });
    }));
  }
  
  // === Mat-autocomplete (displayWith) ===
  public displayFn(subject:any) {
    if (subject) {
      return subject.valueInCombo; // valueInCombo será el valor traducido
    } else {
      return undefined;
    }
  }
  
  /* jalapont: DS2-T14805 - comprueba que el valor del Input sea alfanumérico */
  public keyPressAlphaNumericOnValue(event) {
    this.notAllowedChar = false;     
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      this.notAllowedChar = true;
      return false;
    }
  }
  
  // No permitimos la edición manual del campo Clase de Documento
  public keyPressOnType(event){
    if(event.keyCode == 9){ // Permitido botón de tabular
      return true 
    }
    else {
      return false;
    }
  }

  /* jalapont: DS2-T14805 - comprueba que el valor copiado y pegado no contenga caracteres especiales */
  public pasteOnCIF(event: ClipboardEvent) {
    this.notAllowedChar = false; 
    let clipboardData = event.clipboardData;
    let pastedText = clipboardData.getData('text');
    var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    var test = format.test(pastedText)
    if(test){
      this.notAllowedChar = true; 
    }
    return !test;
  }

  private setCodeOrCompany(data: string){
    const length = data.length;
    let integers = 0;
    const regX = new RegExp("([0-9])");

    // Count numbers
    for (let i = 0; i < data.length; i++) {
      if (regX.test(data.charAt(i))) {
        integers++;
      }
    }

    if (length - integers < 3 ) {
      // Too many numbers:
      // jalapont: DS2-T14805 - comprobamos si arrastramos caracteres no permitidos del formulario anterior
      var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      var test = format.test(data)
      if(test){
        this.notAllowedChar = true;
        data = ""; // si hay caracteres no permitidos, no recuperamos el valor del form anterior
        this.form.patchValue ({code: data});
      } else{
        this.notAllowedChar = false;
        this.form.patchValue ({code: data});
      }
    } else {
      // Too many characters:
      this.form.patchValue ({company: data});
    }
  }

  /* jalapont: RMS #5400 - se guardan los nuevos campos
  - Se introduce la variable loading para deshabilitar el botón de crear */
  public save() {
    if (this.form.dirty) {
      this.loading = true; //La variable pasa a true cuando el usuario pulsa en crear
      let type: string =  this.form.get('type').value.code;
      let code: string =  this.form.get('code').value;
      let company: string =  this.form.get('company').value;

      const postContractorDto: PostContractorDto = new PostContractorDto(type, code, company, null);

      this.subscriptions.push(this._contractor.postContractor(postContractorDto).subscribe(data => {
        console.log("data: "+ JSON.stringify(data));
        if (data) {
          this.loading = false; //se pone a false cuando deja de cargar datos
          alert('Empresa creada con éxito');
          this.dialogRef.close(data);
        }
      }, err => {
        if (err) {
          this.handleErrors(err.status);
          this.loading = false; //se pone a false cuando deja de cargar datos
        }
      }));
    }
  }

  public close() {
    this.dialogRef.close();
  }

  public handleErrors(status: any){
    switch(status){
      case 409:
        this.errExistValue = true; // "El CIF indicado ya existe. Introduzca otro para poder crear la empresa"
        break;
      case 412:
        this.errDocumentInvalid = true; // "El valor del documento no es válido"
        break;
      default:
        this.errGeneric = true;
    }
  }
}
