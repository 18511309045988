import { Component, OnInit } from '@angular/core';
import { SessionService } from 'src/app/services/session/session.service';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  public bHome = false;

  constructor(public session: SessionService, public route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    if (this.router.url === '/home') {
      this.bHome = true;
    }
  }

}
