import { Component, OnInit } from '@angular/core';
import { SessionService } from '../../services/session/session.service';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  constructor(private session: SessionService, private lang: LanguageService) { }

  ngOnInit() {
    this.checkCookie();
  }

  checkCookie() {
    if (this.session.checkSession()) {
      return true;
    } else {
      // Si no hay sesión, borrar el storage
      localStorage.clear();
      return false;
    }
  }

  login() {
    this.session.login();
  }

  logout() {
    this.session.logout();
  }
}
