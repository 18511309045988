import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ContractorInterface } from 'src/app/interfaces/contrator.interface';
import { DivisionInterface } from 'src/app/interfaces/division.interface';
import { UtilService } from 'src/app/services/utils/util.service';
import { AreaInterface } from 'src/app/interfaces/area.interface';
import { ActivityInterface } from 'src/app/interfaces/activity.interface';
import { ContractInterface } from '../../interfaces/contract.interface';
import { ResponsableInterface } from 'src/app/interfaces/responsable.interface';
import { ExpedientService } from 'src/app/services/expedient/expedient.service';
import { ExpedientDetailsInterface } from 'src/app/interfaces/expedientDetails.interface';
import { BusinessLineInterface } from 'src/app/interfaces/businessLine.interface';
import { ApprovedRejectedExpedientComponent } from 'src/app/dialogs/approved-rejected-expedient/approved-rejected-expedient.component';
import { OneHistoryInterface } from 'src/app/interfaces/one-history.interface';
import { DeniedExpedientComponent } from 'src/app/dialogs/denied-expedient/denied-expedient.component';
import { ContractorService } from 'src/app/services/contractor/contractor.service';
import { EmailResponseInterface } from 'src/app/interfaces/emailResponse.interface';
import { PermissionsService } from '../../services/permissions/permissions.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-expedient-details',
  templateUrl: './expedient-details.component.html',
  styleUrls: ['./expedient-details.component.scss']
})
export class ExpedientDetailsComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription[] = [];
  public idExpedient: number;
  public expedientDetails: ExpedientDetailsInterface;
  public addEditVisible: boolean;
  public detailsViewVisible: boolean;
  public approvedButton: boolean;
  public rejectedButton: boolean;
  public deniedButton: boolean;
  public editButton: boolean;
  public detailsButton: boolean;
  public titleToogleButton: string;
  public lastAction: OneHistoryInterface;
  public arrayEmails: EmailResponseInterface[] = [];
  public hasPerms = false;

  constructor(
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private _expedient: ExpedientService,
    private _util: UtilService,
    private _perms: PermissionsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.titleToogleButton = 'Editar';
    this.subscriptions.push(this._perms.getPermissions().subscribe((user) => {
      this.hasPerms = user.isUcc;
    }));
    this.subscriptions.push(this.route.params.subscribe(id => {
      const reg = /^\d+$/;
      if (id.id) {
        if (!reg.test(id.id)) {
          this.router.navigateByUrl('error');
        }
      }

      this.idExpedient = +id['id']; // (+) converts string 'id' to a number
      // TODO No se comprueba que el id recibido sea un id de expediente válido?
      if (this.idExpedient) {
        this.detailsViewVisible = true;
        this.addEditVisible = false;
        this.approvedButton = false;
        this.deniedButton = false;
        this.rejectedButton = false;
        this.getOneExpedient(this.idExpedient);
      } else {
        this.detailsViewVisible = false;
        //this.editButton = true;
        this.addEditVisible = true;
        this.detailsButton = false;
        this.editButton = true;
      }
    }));
  }

  public openNoCommentDialog(action: string) {
    const dialogRef = this.dialog.open(ApprovedRejectedExpedientComponent, {
      width: '350px',
      data: { action: action, idExpedient: this.idExpedient }
    });
    this.subscriptions.push(dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    }));
  }

  public openCommentDialog(): void {
    const dialogRef = this.dialog.open(DeniedExpedientComponent, {
      width: '350px',
      data: { idExpedient: this.idExpedient }
    });
    this.subscriptions.push(dialogRef.afterClosed().subscribe(result => {
      console.log(result);
    }));
  }

  public getOneExpedient(id: number) {
    //Obtaining the Details:
    this.subscriptions.push(this._expedient.getExpedientDetails(id).subscribe(expedient => {
      this.expedientDetails = expedient;
      //Obtaining the Last Status
      this.subscriptions.push(this._expedient.getLastModified(id).subscribe((data: OneHistoryInterface) => {
        this.lastAction = data;
        if (this.lastAction.action == 'Approved' || this.lastAction.action == 'Rejected') {
          this.deniedButton = false;
          this.rejectedButton = false;
          this.approvedButton = false;
          this.editButton = false;
        } else if (this.lastAction.action == 'Denied') {
          this.approvedButton = true;
          this.rejectedButton = true;
          this.editButton = true;
        } else if (this.lastAction.action == 'Modified' || this.lastAction.action == 'Created' || this.lastAction.action == 'Error') {
          this.deniedButton = true;
          this.rejectedButton = true;
          this.approvedButton = true;
          this.editButton = true;
        }
      }));
    }, (error: any) => {
      console.log(error);
      this.router.navigateByUrl('error');
    }));
  }

  public HandleloadError(err?: any) {
    this._util.handleLoadError(err);
    this._util.goHome();
  }

  public toogleView() {
    if (this.detailsViewVisible) {
      this.detailsViewVisible = false;
      this.addEditVisible = true;
      this.titleToogleButton = 'Ver detalles';
      this.detailsButton = true;
    } else {
      this.detailsViewVisible = true;
      this.addEditVisible = false;
      this.titleToogleButton = 'Editar';
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.dialog.closeAll();
  }

}



