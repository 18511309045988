import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { SessionService } from '../services/session/session.service';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private _session: SessionService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    // Comprobar si el usuario tiene token
    if (this._session.checkSession()) {
      // Comprobar si existe fecha de expiración almacenada en local
      if (localStorage.getItem('exp_date')) {
        // Comprobar si la fecha almacenada es anterior a la actual
        if (!this._session.checkExpiredDate(Date.parse(localStorage.getItem('exp_date')))) {
          return true;
        } else {
            return this._session.refresh().pipe(map(response => {
              if (response) {
                this._session.setCookieToken(response.access_token, response.expires_in);
                return true;
              } else {
                return false;
              }
            }), catchError((err) => {
              return of(false);
            }));
        }
      } else {
        this._session.logout();
        return false;
      }
    } else {
      this._session.logout();
      return false;
    }
  }
  
}
