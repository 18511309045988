import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExpedientDetailsInterface } from 'src/app/interfaces/expedientDetails.interface';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { AddExpedientInterface } from 'src/app/interfaces/addExpedient.interface';
import { environment } from 'src/environments/environment';
import { runtimeEnvironment } from 'src/environments/runtimeEnvironment';
import { ExpedienteListInterface } from 'src/app/interfaces/expedient-list-response.interface';
import { EditExpedientDto } from 'src/app/dto/editExpedient.dto';
import { ContractorInterface } from 'src/app/interfaces/contrator.interface';
import { ContractInterface } from 'src/app/interfaces/contract.interface';
import { BusinessLineInterface } from 'src/app/interfaces/businessLine.interface';
import { AreaInterface } from 'src/app/interfaces/area.interface';
import { DivisionInterface } from 'src/app/interfaces/division.interface';
import { ActivityInterface } from 'src/app/interfaces/activity.interface';
import { ResponsableInterface } from 'src/app/interfaces/responsable.interface';
import { ExpedientActionDto } from 'src/app/dto/expedientAction.dto';
import { CheckInterface } from 'src/app/interfaces/check.interface';
import { HistoricalExpedientInterface } from 'src/app/interfaces/historicalExpedient.interface';
import { OneHistoryInterface } from 'src/app/interfaces/one-history.interface';
import { SessionService } from '../session/session.service';
import { CodeInterface } from 'src/app/interfaces/code.interface';

@Injectable({
  providedIn: 'root'
})
export class ExpedientService {

  private expedientUrl: string;
  private token: string;
  private filterAction: string = 'all';
  private searchByExpCode: string = '';
  private searchByCif: string = '';

  constructor(public _http: HttpClient, private _session: SessionService) {
    this.expedientUrl = runtimeEnvironment.test ? runtimeEnvironment.test + '/expedient' : `${environment.apiUrl}/expedient`;
    this._session.getTokenSubj$().subscribe((usertoken: string) => {
      if (usertoken) {
        this.token = usertoken;
      }
    });
  }

  public getExpedientDetails(idExpedient: number): Observable<ExpedientDetailsInterface> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this._http.get<ExpedientDetailsInterface>(`${this.expedientUrl}/${idExpedient}/details`, { headers: reqHeader });
  }
  public putExpedient(id: number, newExpedient: EditExpedientDto): Observable<any> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    console.log("Editar expediente - expediente, idContractors: " + JSON.stringify(newExpedient.idContractors));
    console.log("Editar expediente - expediente: " + JSON.stringify(newExpedient));

    return this._http.put<any>(`${this.expedientUrl}/update/${id}`, newExpedient, { headers: reqHeader });
  }

  public addExpedient(expedient: AddExpedientInterface): Observable<AddExpedientInterface> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token,

    });
    console.log("Crear expediente - expediente: " + JSON.stringify(expedient));
    return this._http.post<AddExpedientInterface>(`${this.expedientUrl}`, expedient, { headers: reqHeader });
  }

  public getAll(limit?: any, page?: any): Observable<ExpedienteListInterface> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    const limite = limit;
    const paginas = page;
    console.log('Limite -> ' + limite + '\nPáginas -> ' + paginas);
    // TODO implementar paginacion
    if (limite !== null && limite !== undefined) {
      if (paginas !== null && paginas !== undefined) {
        console.log('Hay límite y página');
        return this._http.get<ExpedienteListInterface>(`${this.expedientUrl}?limit=${limit}&page=${page}`, { headers: reqHeader });
      } else {
        console.log('Hay límite');
        return this._http.get<ExpedienteListInterface>(`${this.expedientUrl}?limit=${limit}`, { headers: reqHeader });
      }
    } else {
      console.log('No hay parámetros');
      return this._http.get<ExpedienteListInterface>(this.expedientUrl, { headers: reqHeader });
    }
  }

  public getDivisionExpedient(idExpedient: number): Observable<DivisionInterface> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this._http.get<DivisionInterface>(`${this.expedientUrl}/${idExpedient}/division`, { headers: reqHeader });
  }

  public getAreaExpedient(idExpedient: number): Observable<AreaInterface []> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this._http.get<AreaInterface []>(`${this.expedientUrl}/${idExpedient}/area`, { headers: reqHeader });
  }

  public getBusinessModel(idExpedient: number): Observable<BusinessLineInterface> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this._http.get<BusinessLineInterface>(`${this.expedientUrl}/${idExpedient}/businessModel`, { headers: reqHeader });
  }

  public getContractsExpedient(idExpedient: number): Observable<ContractInterface[]> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this._http.get<ContractInterface[]>(`${this.expedientUrl}/${idExpedient}/contracts`, { headers: reqHeader });
  }

  public getContractorsExpedient(idExpedient: number): Observable<ContractorInterface[]> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this._http.get<ContractorInterface[]>(`${this.expedientUrl}/${idExpedient}/contractors`, { headers: reqHeader });
  }

  public getAcvityExpedient(idExpedient: number) {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this._http.get<ActivityInterface>(`${this.expedientUrl}/${idExpedient}/activity`, { headers: reqHeader });
  }

  public getSpmsExpedient(idExpedient: number): Observable<ResponsableInterface[]> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this._http.get<ResponsableInterface[]>(`${this.expedientUrl}/${idExpedient}/spms`, { headers: reqHeader });
  }

  public getUccsExpedient(idExpedient: number): Observable<ResponsableInterface[]> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this._http.get<ResponsableInterface[]>(`${this.expedientUrl}/${idExpedient}/uccs`, { headers: reqHeader });
  }

  public getUbsExpedient(idExpedient: number): Observable<ResponsableInterface[]> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this._http.get<ResponsableInterface[]>(`${this.expedientUrl}/${idExpedient}/ubs`, { headers: reqHeader });
  }

  public getTypeCheck(idExpedient: number): Observable<CheckInterface> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this._http.get<CheckInterface>(`${this.expedientUrl}/${idExpedient}/typeCheck`, { headers: reqHeader });
  }

  public getLastModified(idExpedient: number): Observable<OneHistoryInterface> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this._http.get<OneHistoryInterface>(`${this.expedientUrl}/${idExpedient}/lastModified`, { headers: reqHeader });
  }

  public getOwner(idExpedient: number): Observable<OneHistoryInterface> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this._http.get<OneHistoryInterface>(`${this.expedientUrl}/${idExpedient}/owner`, { headers: reqHeader });
  }

  public makeAction(idExpedient: number, expActionDto: ExpedientActionDto): Observable<any> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this._http.post<any>(`${this.expedientUrl}/${idExpedient}/action`, expActionDto, { headers: reqHeader });
  }
  
  // Llama al backend para borrar el último registro de error generado (dado que se generan dos iguales)
  public deleteLastError(idExpedient: number): Observable<any> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    console.log("token: "+this.token);
    return this._http.get<any>(`${this.expedientUrl}/${idExpedient}/histories/deleteLast`, { headers: reqHeader });
  }

  public getHistorical(idExpedient: number): Observable<HistoricalExpedientInterface> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this._http.get<HistoricalExpedientInterface>(`${this.expedientUrl}/${idExpedient}/histories`, { headers: reqHeader });
  }

  public getHistoricalTemporal(): Observable<HistoricalExpedientInterface> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this._http.get<HistoricalExpedientInterface>('assets/data/historical/historicalList.json', { headers: reqHeader });
  }

  public existsByCode(code: string): Observable<any> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    let codeEncoded = encodeURIComponent(code);
    return this._http.get<CodeInterface>(`${this.expedientUrl}/exists/`+codeEncoded, { headers: reqHeader });
  }

  public existsByContract(contract: string): Observable <any> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    let contractEncoded = encodeURIComponent(contract);
    return this._http.get<CodeInterface>(`${this.expedientUrl}/existsContract/`+contractEncoded, { headers: reqHeader });
  }

  public getExpedientEmailsList(idExpedient: number): Observable<any> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this._http.get(`${this.expedientUrl}/${idExpedient}/emails`, { headers: reqHeader });
  }

  public getExpedientsFiltered(filterAction: string, allExpedientsUcc: boolean, expCodeValue?:string, searchValue?: string): Observable<ExpedienteListInterface> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });

    let urlExped = `${this.expedientUrl}/list`;

    if (allExpedientsUcc) {
      urlExped = urlExped + '?type=all';
    }

    if (filterAction) {
      this.setFilterActionValue(filterAction);
      urlExped = urlExped.includes('?') ? urlExped + '&action=' + filterAction : urlExped + '?action=' + filterAction;
    }

    if (searchValue) {
      this.setSearchByCifValue(searchValue);
      urlExped = urlExped.includes('?') ? urlExped + '&search=' + searchValue : urlExped + '?search=' + searchValue;
    }

    // jalapont - DS2-T14880: añadir filtrado por 'Código de expediente'
    if(expCodeValue){
      this.setSearchByExpCode(expCodeValue);
      urlExped = urlExped.includes('?') ? urlExped + '&code=' + expCodeValue : urlExped + '?code=' + expCodeValue;
    }

    console.log("getExpedientsFiltered URL: " + urlExped);

    //Se invoca getListExpedients() en expedient-controller.js
    return this._http.get<ExpedienteListInterface>(urlExped, { headers: reqHeader });
  }

  public getFilterActionValue() {
    return this.filterAction;
  }

  public setFilterActionValue(newActionValue: string) {
    this.filterAction = newActionValue;
  }

  public getSearchByExpCode() {
    return this.searchByExpCode;
  }

  public setSearchByExpCode(newSearchValue: string) {
    this.searchByExpCode = newSearchValue;
  }

  public getSearchByCifValue() {
    return this.searchByCif;
  }

  public setSearchByCifValue(newSearchValue: string) {
    this.searchByCif = newSearchValue;
  }

}
