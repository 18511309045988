import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BusinessLineInterface } from 'src/app/interfaces/businessLine.interface';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { runtimeEnvironment } from 'src/environments/runtimeEnvironment';
import { SessionService } from '../session/session.service';

@Injectable({
  providedIn: 'root'
})
export class BusinessLineService {

  private bLineUrl: string;
  private token: string;

  constructor(public _http: HttpClient, private _session: SessionService) {
    this.bLineUrl = runtimeEnvironment.test ? runtimeEnvironment.test + '/datamaster/businessModels' : `${environment.apiUrl}/datamaster/businessModels`;
    this._session.getTokenSubj$().subscribe((usertoken: string) => {
      if (usertoken != null && usertoken != undefined && usertoken != '') {
        this.token = usertoken;
      }
    });
  }

  public getAllBusinessLine(filterText: string): Observable<BusinessLineInterface[]> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    let filter: string;
    let observable: Observable<BusinessLineInterface[]>;
    if (filterText != undefined && filterText != '' && filterText != null) {
      filter = filterText.toString().trim().toLowerCase();
      observable = this._http.get<BusinessLineInterface[]>(`${this.bLineUrl}?name=${filter}`, { headers: reqHeader });
    } else {
      observable = this._http.get<BusinessLineInterface[]>(this.bLineUrl, { headers: reqHeader });
    }
    return observable;
  }

}
