import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { ExpedientService } from 'src/app/services/expedient/expedient.service';
import { UtilService } from 'src/app/services/utils/util.service';
import { ExpedientActionDto } from 'src/app/dto/expedientAction.dto';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-denied-expedient',
  templateUrl: './denied-expedient.component.html',
  styleUrls: ['./denied-expedient.component.scss']
})
export class DeniedExpedientComponent implements OnInit, OnDestroy {

  public action: string;
  public idExpedient: number;
  public title: string;
  public form: FormGroup;

  private subscriptions: Subscription[] = [];
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DeniedExpedientComponent>,
  private fb: FormBuilder, private _expedient: ExpedientService, private _util: UtilService) { }

  ngOnInit() {
    this.obtainDataInjected();
    this.createForm();
    this.action = 'Denied';
  }

  public accept() {
    const newAction: ExpedientActionDto = new ExpedientActionDto(
      this.action, this.form.get('comment').value.toString());
    this.subscriptions.push(this._expedient.makeAction(this.idExpedient, newAction).subscribe(data => {
      this.dialogRef.close('Closed by accept button.');
      this._util.goHome();
    }));
  }

  public cancel() {
    this.dialogRef.close('Closed by cancel button.');
  }

  public obtainDataInjected() {
    if (this.data) {
      if (this.data.idExpedient) {
        this.idExpedient = this.data.idExpedient;
      }
    }
  }

  private createForm() {
    this.form = this.fb.group({
      comment: [null, Validators.compose([Validators.required])]
    });
  }
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
