import { StatusInterface } from '../interfaces/status.interface';

export class EditExpedientDto {
    code?: string;
    title?: string;
    idBusinessModel?:number;
    idDivision?: number;
    idArea?: StatusInterface[];
    action?: string;
    idActivity?: number;
    initialDate?: string;
    finalDate?: string;
    contracts?: StatusInterface[];
    idResponsablesUb?: StatusInterface[];
    idResponsablesUcc?: StatusInterface[];
    idResponsablesSpm?: StatusInterface[];
    type_check: number;
    idContractors?: StatusInterface[];
    responsables?: StatusInterface[];
    has_access: string;
    id_legalForm: number; // jalapont: DS2-T14819
}
