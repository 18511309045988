import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-expedient-history',
  templateUrl: './expedient-history.component.html',
  styleUrls: ['./expedient-history.component.scss']
})
export class ExpedientHistoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
