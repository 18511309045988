import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ResponsableInterface } from 'src/app/interfaces/responsable.interface';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { runtimeEnvironment } from 'src/environments/runtimeEnvironment';
import { SessionService } from '../session/session.service';

@Injectable({
  providedIn: 'root'
})
export class ResponsableService {

  private responsableUrl: string;
  private token: string;

  constructor(public _http: HttpClient, private _session: SessionService) {
    this.responsableUrl = runtimeEnvironment.test ? runtimeEnvironment.test + '/user/groups/' : `${environment.apiUrl}/user/groups/`;
    this._session.getTokenSubj$().subscribe((usertoken: string) => {
      if (usertoken) {
        this.token = usertoken;
      }
    });
  }

  public getAllResponsableUb(filterTextReceived: string, sidBusinessLine: number): Observable<ResponsableInterface[]> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    let textReceived = filterTextReceived;
    return this._http.get<ResponsableInterface[]>(this.responsableUrl + 'UB' + '?businessLine=' + sidBusinessLine + '&fullName=' + textReceived, { headers: reqHeader });
  }

  public getAllResponsableUcc(filterTextReceived: string): Observable<ResponsableInterface[]> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    let textReceived = filterTextReceived.toString().toLowerCase().trim();
    return this._http.get<ResponsableInterface[]>(this.responsableUrl + 'UCC' + '?fullName=' + textReceived, { headers: reqHeader });
  }

  public getAllResponsableSpm(filterTextReceived: string): Observable<ResponsableInterface[]> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    let textReceived = filterTextReceived.toString().toLowerCase().trim();
    return this._http.get<ResponsableInterface[]>(this.responsableUrl + 'SPM' + '?fullName=' + textReceived, { headers: reqHeader });
  }

}
