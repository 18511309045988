import { Component, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { LanguageService } from 'src/app/services/language/language.service';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LanguageComponent implements OnInit {
  public images = [
    'flag-icon flag-icon-es',
    'flag-icon flag-icon-pt',
    'flag-icon flag-icon-it',
    'flag-icon flag-icon-gb'
  ];
  items: any[] = [];
  url: string;
  rotateImg = 0;
  selected: string;
  constructor(private langService: LanguageService) { }

  ngOnInit() {
    this.init();
    this.selected = this.langService.getLang();
  }

  changeLang(value: string) {
    this.langService.changeLanguage(value);

  }

  init() {
    for (let i = 0; i < this.images.length; i++) {
      let value = '';
      let name = '';
      if (i === 0) {
        value = 'es-ES';
        name = 'Español';
      } else if (i === 1) {
        value = 'pt';
        name = 'Português';
      } else if (i === 2) {
        value = 'it-IT';
        name = 'Italiano';
      } else {
        value = 'en-GB';
        name = 'English';
      }
      this.items.push({
        imgSrc: this.getImgSrc(i),
        imgHeight: Math.floor(Math.random() * 50 + 150),
        imgValue: value,
        // tslint:disable-next-line:object-literal-shorthand
        name: name

      });

      this.rotateImg++;
      if (this.rotateImg === this.images.length) {
        this.rotateImg = 0;
      }
    }
  }
  getImgSrc(i: number) {
    const src = this.images[i];
    this.rotateImg++;
    if (this.rotateImg === this.images.length) {
      this.rotateImg = 0;
    }
    return src;
  }
}
