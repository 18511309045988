import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ExpedientInterface } from 'src/app/interfaces/expedient.interface';
import { Router } from '@angular/router';
import { ExpedientService } from 'src/app/services/expedient/expedient.service';
import { OneHistoryInterface } from 'src/app/interfaces/one-history.interface';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-expedient-one-item',
  templateUrl: './expedient-one-item.component.html',
  styleUrls: ['./expedient-one-item.component.scss']
})
export class ExpedientOneItemComponent implements OnInit, OnDestroy {

  @Input() expedient: ExpedientInterface;
  private subscriptions: Subscription[] = [];
  private approved = '';
  private modified = '';
  private created = '';
  private denied = '';
  private rejected = '';
  private error = '';

  constructor(private router: Router, private _expedient: ExpedientService, private translate: TranslateService) {
    this.subscriptions.push(this.translate.get(['statusExp']).subscribe(translations => {
      this.approved = translations.statusExp.approved;
      this.modified = translations.statusExp.modified;
      this.created = translations.statusExp.created;
      this.denied = translations.statusExp.denied;
      this.rejected = translations.statusExp.rejected;
      this.error = translations.statusExp.error;
    }));
  }

  ngOnInit() {
    this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
      this.translate.get(['statusExp']).subscribe(translations => {
        this.approved = translations.statusExp.approved;
        this.modified = translations.statusExp.modified;
        this.created = translations.statusExp.created;
        this.denied = translations.statusExp.denied;
        this.rejected = translations.statusExp.rejected;
        this.error = translations.statusExp.error;

      });
    }));
  }

  onCardClick() {
    this.router.navigate(['/expedient-details', this.expedient.id]);
  }

  // not using anymore
  getLastModified(idExpedient: number) {
    this.subscriptions.push(this._expedient.getLastModified(idExpedient).subscribe((data: OneHistoryInterface) => {
      this.expedient.action = this.expedient.action;
      this.expedient.updatedAt = data.createdAt;
      //  this.expedient.sUpdater = { username: data.username };
    }));
  }

  // not using anymore
  getOwner(idExpedient: number) {
    this.subscriptions.push(this._expedient.getOwner(idExpedient).subscribe((data: OneHistoryInterface) => {
      //TODO cuando la api devuelva un nombre quitar el moqueo por data.name
      //  this.expedient.sUser = { username: data.username };
    }));
  }

  translateStatus(status: string) {
    switch (status.toLowerCase()) {
      case "approved":
        return this.approved;
      case "modified":
        return this.modified;
      case "created":
        return this.created;
      case "denied":
        return this.denied;
      case "rejected":
        return this.rejected;
      case "error":
        return this.error;
    }

  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
