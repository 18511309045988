import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ContractorInterface } from 'src/app/interfaces/contrator.interface';
import { PostContractorDto } from 'src/app/dto/postContractor.dto';
import { environment } from 'src/environments/environment';
import { runtimeEnvironment } from 'src/environments/runtimeEnvironment';
import { EmailResponseInterface } from 'src/app/interfaces/emailResponse.interface';
import { ContractorCreatedInterface } from 'src/app/interfaces/contractorCreated.interface';
import { PostEmailDto } from 'src/app/dto/postEmail.dto';
import { SessionService } from '../session/session.service';

@Injectable({
  providedIn: 'root'
})
export class ContractorService {

  private contractorUrl: string;
  public responsableUrl: string;
  private token: string;

  constructor(public _http: HttpClient, private _session: SessionService) {
    this.contractorUrl = runtimeEnvironment.test ? runtimeEnvironment.test + '/contractor' : `${environment.apiUrl}/contractor`;
    this.responsableUrl = runtimeEnvironment.test ? runtimeEnvironment.test + '/responsable' : `${environment.apiUrl}/responsable`;
    this._session.getTokenSubj$().subscribe((usertoken: string) => {
      if (usertoken) {
        this.token = usertoken;
      }
    });
  }

  public getContractorListFilterBy(bm: number, filterText: string, businessModelId: number): Observable<ContractorInterface[]> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    let textReceived: string;
    if (filterText == undefined || filterText == null || filterText == '') {
      textReceived = '';
    } else {
      textReceived = filterText.toString().toLowerCase().trim();
    }

    // jalapont: DS2-T14819 - modificada la invocación para extraer contratistas (ahora se requiere el businessModelId)
    return this._http.get<ContractorInterface[]>(`${this.contractorUrl}/sercae/${bm}/${textReceived}/${businessModelId}`, { headers: reqHeader });
  }
  
  public getContractorListFilterByTemp(filterText: string): Observable<ContractorInterface[]> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    let textReceived: string;
    if (filterText == undefined || filterText == null || filterText == '') {
      textReceived = '';
    } else {
      textReceived = filterText.toString().toLowerCase().trim();
    }
    if (textReceived == 'alv') {
      return this._http.get<ContractorInterface[]>('assets/data/contractor/contractorListAlvaro.json');
    }
    else if (textReceived == 'pep') {
      return this._http.get<ContractorInterface[]>('assets/data/contractor/contractorListPepe.json');
    }
    else if (textReceived == 'lui') {
      return this._http.get<ContractorInterface[]>('assets/data/contractor/contractorListLuis.json');
    }
    else if (textReceived == '') {
      return this._http.get<ContractorInterface[]>('assets/data/contractor/contractorList.json');
    }
    else {
      //provisional para evitar errores de no devolver nada, al no haber resultado devolvemos la lista
      //de alvaros
      return this._http.get<ContractorInterface[]>('assets/data/contractor/contractorListAlvaro.json');
    }
  }

  public postContractor(postContractorDto: PostContractorDto): Observable<ContractorCreatedInterface> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    /* jalapont: RMS #5400 - Se añade traza para ver de qué hacemos POST */
    console.log("postContractor.01 URL - "+this.contractorUrl);
    console.log("postContractor.02 DATA - "+ JSON.stringify(postContractorDto));    
    return this._http.post<ContractorCreatedInterface>(`${this.contractorUrl}`, postContractorDto, { headers: reqHeader });
  }

  public getAllNoOfficialEmails(idContractor: number): Observable<EmailResponseInterface[]> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this._http.get<EmailResponseInterface[]>(`${this.contractorUrl}/${idContractor}/email`, { headers: reqHeader });
  }

  public getAllOfficialEmails(idContractor: number): Observable<EmailResponseInterface[]> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this._http.get<EmailResponseInterface[]>(`${this.contractorUrl}/emails/sercae/${idContractor}`, { headers: reqHeader });
  }

  public getOfficialEmailsFilterBy(idContractor: number, filterText: string): Observable<EmailResponseInterface[]> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    let textReceived: string;
    if (filterText == undefined || filterText == null || filterText == '') {
      textReceived = '';
    } else {
      textReceived = filterText.toString().toLowerCase().trim();
    }

    return this._http.get<EmailResponseInterface[]>(`${this.contractorUrl}/emails/sercae/${idContractor}/${textReceived}`, { headers: reqHeader });
  }
  
  public postEmail(postEmailDto: PostEmailDto): Observable<any> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this._http.post<any>(`${this.responsableUrl}`, postEmailDto, { headers: reqHeader, observe: 'response' });
  }
}
