import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ExpedientActionDto } from 'src/app/dto/expedientAction.dto';
import { ExpedientService } from 'src/app/services/expedient/expedient.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { UtilService } from 'src/app/services/utils/util.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-approved-rejected-expedient',
  templateUrl: './approved-rejected-expedient.component.html',
  styleUrls: ['./approved-rejected-expedient.component.scss']
})
export class ApprovedRejectedExpedientComponent implements OnInit, OnDestroy {

  public idExpedient: number;
  public action: string;
  public title: string;

  private subscriptions: Subscription[] = [];

  constructor(private _expedient: ExpedientService, @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ApprovedRejectedExpedientComponent>, public _util: UtilService) { }

  ngOnInit() {
    this.obtainDataInjected();
  }
  public accept() {
    const newAction: ExpedientActionDto = new ExpedientActionDto(this.action);
    this.subscriptions.push(this._expedient.makeAction(this.idExpedient, newAction).subscribe(data => {
      this.dialogRef.close('Closed by accept button.');
      this._util.goHome();
    }));
  }
  public cancel() {
    this.dialogRef.close('Closed by cancel button.');
  }
  public obtainDataInjected() {
    if (this.data) {
      this.action = this.data.action.toString();
      this.idExpedient = this.data.idExpedient;
      if (this.action == 'Approved') {
        this.title = 'Aceptar solicitud';
      } else if (this.action == 'Denied') {
        this.title = 'Denegar solicitud';
      } else if (this.action == 'Rejected') {
        this.title = 'Descartar solicitud'
      }
    }

  }
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
