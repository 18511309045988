import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private lang: string;
  constructor(private translate: TranslateService) {

  }
  changeLanguage(value: string) {
    console.log(value);
    try {
      this.useLang(value);
      this.setLang(value);
    } catch {
      this.useLang(value);
      this.setLang(value);
    } finally {
      this.useLang(value);
      this.setLang(value);
    }
  }

  setDefaultLang(value: string) {
    this.translate.setDefaultLang(value);
  }

  useLang(value: string) {
    this.translate.use(value);
  }

  getLang() {
    return this.lang;
  }

  setLang(value: string) {
    this.lang = value;
  }
}
