import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { runtimeEnvironment } from 'src/environments/runtimeEnvironment';
import { RefreshToken } from 'src/app/interfaces/refreshToken.interface';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private backUrl: string;
  private token: string;
  public tokenBehSubj$ = new  BehaviorSubject<string>('');
  private expirationTime: number;

  constructor(private cookie: CookieService, private http: HttpClient, private router: Router) {
    this.backUrl = runtimeEnvironment.test ? runtimeEnvironment.test : environment.apiUrl;
    // Cuanto mayor el tiempo de expiración, antes refrescará el token
    // Si tiempo de expiración igual a cero -> token no se refresca nunca
    this.expirationTime = 3600000; // Este tiempo está en milisegundos (3600000ms -> 1h)
    this.checkSession();
  }

  public getTokenString = () => this.token;

  // Este es el observable al que deben suscribirse los componentes que necesiten hacer uso del token
  public getTokenSubj$ = () => this.tokenBehSubj$.asObservable();

  // Sirve para enviar el nuevo valor del token a todos los suscriptores
  public emitToken(value: string) {
    this.token = value;
    this.tokenBehSubj$.next(this.getTokenString());
  }

  // Comprueba si existe un token almacenado como cookie y
  // en caso de existir token, emite su valor
  public checkSession(): boolean {
    if (this.cookie.check('access_token')) {
      this.emitToken(this.cookie.get('access_token'));
      return true;
    } else {
      return false;
    }
  }

  public setCookieToken(value: string, expires: number) {
    let expirationCookieMilisecs = Date.now() + (expires * 1000);
    let expirationCookieDate = new Date(expirationCookieMilisecs);
    this.emitToken(value);
    localStorage.setItem('exp_date', expirationCookieDate.toUTCString());
    this.cookie.set('access_token', value, expirationCookieDate, '/');
  }

  // Método para comprobar si la cookie ha expirado o en caso de quedar poco para expirar
  public checkExpiredDate(expirationDateMili: number): boolean {
    //Comprobar que los milisegundos recibidos no sean menores al Date actual
    if (expirationDateMili < Date.now()) {
      // Borrar localStorage
      localStorage.removeItem('exp_date');
      // Borrar cookie de sesión
      this.cookie.delete('access_token', '/');
      return null;
    } else {
      // Comprobar si el tiempo que queda para expirar la cookie es menor a X horas
      if (expirationDateMili - Date.now() < this.expirationTime) {
        return true;
      } else {
        return false;
      }
    }
  }

  public refresh(): Observable<RefreshToken> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.getTokenString()
    });
    return this.http.get<RefreshToken>(`${this.backUrl}/user/refresh`, { headers: reqHeader });
  }

  login() {
    window.location.href = `${this.backUrl}/user/login`;
  }

  logout() {
    if (this.checkSession) {
      localStorage.removeItem('exp_date');
      this.cookie.delete('access_token', '/');
    }
    localStorage.clear();
    this.router.navigate(['/home']);
  }
}
