import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { ResponsableService } from 'src/app/services/responsable/responsable.service';
import { ContractorInterface } from 'src/app/interfaces/contrator.interface';
import { PostEmailDto } from 'src/app/dto/postEmail.dto';
import { ContractorService } from 'src/app/services/contractor/contractor.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { EmailResponseInterface } from 'src/app/interfaces/emailResponse.interface';

@Component({
  selector: 'app-add-responsable-dialog',
  templateUrl: './add-responsable-dialog.component.html',
  styleUrls: ['./add-responsable-dialog.component.scss']
})
export class AddResponsableDialogComponent implements OnInit, OnDestroy {

  //Error flags
  public errExistEmail: boolean;
  public errGeneric: boolean;

  public form: FormGroup;
  public description: string;
  public errorDescription: string;
  public postError: boolean;
  public contractor: ContractorInterface;

  private subscriptions: Subscription[] = [];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder, private _contractor: ContractorService,
    public dialogRef: MatDialogRef<AddResponsableDialogComponent>) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.description = 'Añadir responsable';
    this.postError = false;
    this.createForm();
    this.setNameOrEmail();
    // this.obtainInjectedData();
  }

  private createForm() {
    this.form = this.fb.group({
      name: [null, Validators.compose([Validators.required])],
      surname: [null, Validators.compose([Validators.required])],
      secondSurname: [],
      email: [null, Validators.compose([Validators.required, Validators.email])],
      phone: [null, Validators.compose([Validators.required])]
    });
    this.setNameOrEmail();
  }

  private setNameOrEmail() {
    if (this.data.email.indexOf('@') > 0) {
      // It's an email
      this.form.patchValue({ email: this.data.email });
    } else {
      // It's not an email but a "name"
      const splitName = this.data.email.split(' ');
      if (splitName.length === 3) {
        // Data is probably: name surname secondsurname
        this.form.patchValue(
          {
            name: splitName[0],
            surname: splitName[1],
            secondSurname: splitName[2]
          });
      } else if (splitName.length > 3) {
        // Data is probably: name name2 surname secondsurname
        console.log(splitName[length - 1], splitName[length - 2], splitName.slice(0, splitName.length - 2).join(' '));
        this.form.patchValue(
          {
            secondSurname: splitName[splitName.length - 1],
            surname: splitName[splitName.length - 2],
            name: splitName.slice(0, splitName.length - 2).join(' ')
          }
        );
      } else if (splitName.length === 1) {
        // Data is probably of the kind: nam(e)
        this.form.patchValue({ name: this.data.email });
      } else if (splitName.length === 2) {
        // Data is probably of the kind: name surnam(e)
        this.form.patchValue(
          {
            name: splitName[0],
            surname: splitName[1]
          });
      } else {
        // No idea of the data:
        this.form.patchValue({ email: this.data.email });
      }
    }
  }

  public save() {
    if (this.form.dirty) {
      let secondSurname;
      if (this.form.get('secondSurname').value !== null) {
        secondSurname = this.form.get('secondSurname').value;
      } else {
        secondSurname = '';
      }
      const postEmailDto = new PostEmailDto();
      postEmailDto.name = this.form.get('name').value;
      postEmailDto.surname = this.form.get('surname').value;
      postEmailDto.secondSurname = secondSurname;
      postEmailDto.email = this.form.get('email').value;
      postEmailDto.company = this.data.contractor.sid;
      postEmailDto.phone = this.form.get('phone').value;
      this.subscriptions.push(this._contractor.postEmail(postEmailDto).subscribe(data => {
        console.log(JSON.stringify(data));
        if (data) {
          let emailBack: EmailResponseInterface = {
            sid: data.body.sid,
            email: postEmailDto.email,
            name: postEmailDto.name,
            surname: postEmailDto.surname + ' ' + postEmailDto.secondSurname
          };
          this.dialogRef.close(emailBack);
        }
      }, err => {
        if (err) {
          this.handleErrors(err.status)
        }
      }));
    }

  }

  public handleErrors(status: any){
    switch(status){
      case 409:
        this.errExistEmail = true; // "Este email ya se encuentra en uso"
        break;
      default:
        this.errGeneric = true;
    }
  }

  public close() {
    this.dialogRef.close();

  }
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
