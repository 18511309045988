import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SessionService } from 'src/app/services/session/session.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html',
  styleUrls: ['./redirect.component.scss']
})
export class RedirectComponent {
  private token: string;
  private expirationSecs: number;
  private subscriptions: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _session: SessionService
  ) {
    this.subscriptions.push(this.route.queryParams.subscribe(params => {
      this.token = params['access_token'];
      this.expirationSecs = params['expires'];
      this._session.setCookieToken(this.token, this.expirationSecs);
      this.router.navigate(['/home']);
    }));
  }
  
  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
