import { Injectable } from '@angular/core';
import { runtimeEnvironment } from 'src/environments/runtimeEnvironment';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SessionService } from '../session/session.service';
import { DocumentTypeInterface } from 'src/app/interfaces/documentType.interface';
import { ValidatedDocumentInterface } from 'src/app/interfaces/validatedDocument.interface';


@Injectable({
  providedIn: 'root'
})
export class DocumentTypeService {
    private documentTypeUrl: string;
    private documentValidationUrl: string;
    private token: string;

    constructor(public _http: HttpClient, private _session: SessionService) {
        this.documentTypeUrl = runtimeEnvironment.test 
        ? `${runtimeEnvironment.test}/datamaster/documentTypes` 
        : `${environment.apiUrl}/datamaster/documentTypes`;

        this.documentValidationUrl = runtimeEnvironment.test 
        ? `${runtimeEnvironment.test}/datamaster/documentValidation` 
        : `${environment.apiUrl}/datamaster/documentValidation`;

        this._session.getTokenSubj$().subscribe((usertoken: string) => {
            if (usertoken) {
              this.token = usertoken;
            }
        });
    }   

    // Recupera las clases de documento disponibles (ES_CIF, ES_NIF, ES_NIE, OTHER_DOCUMENT)
    public getAllDocumentTypes(){
        const reqHeader = new HttpHeaders({'Authorization': 'Bearer ' + this.token});
        let observable: Observable<DocumentTypeInterface[]>;
        observable = this._http.get<DocumentTypeInterface[]>(`${this.documentTypeUrl}`, { headers: reqHeader });
        return observable;
    }

    // Determina si una Clase de Documento + valor pasan el Validador
    public getDocumentValidation(type: string, value: string){
        const reqHeader = new HttpHeaders({'Authorization': 'Bearer ' + this.token});
        let observable: Observable<ValidatedDocumentInterface>;
        observable = this._http.get<ValidatedDocumentInterface>(`${this.documentValidationUrl}?validator=${type}&code=${value}`, { headers: reqHeader });
        return observable;
    }
}