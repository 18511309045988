import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { runtimeEnvironment } from 'src/environments/runtimeEnvironment';
import { PermissionsInterface } from '../../interfaces/permissions.interface';
import { SessionService } from '../session/session.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {

  private userUrl: string;
  private token: string;

  constructor(public http: HttpClient, private _session: SessionService) {
    this.userUrl = runtimeEnvironment.test ? runtimeEnvironment.test + '/user' : `${environment.apiUrl}/user`;
    this._session.getTokenSubj$().subscribe((usertoken: string) => {
      if (usertoken) {
        this.token = usertoken;
      }
    });
  }

  public getPermissions(): Observable<PermissionsInterface> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.get<PermissionsInterface>(`${this.userUrl}/perms`, { headers: reqHeader });
  }

  public getPermissionsResponse(): Observable<any> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    return this.http.get(`${this.userUrl}/me`, { headers: reqHeader, observe: 'response' });
  }
}
