import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { ContractorInterface } from 'src/app/interfaces/contrator.interface';
import { BusinessLineInterface } from 'src/app/interfaces/businessLine.interface';
import { ContractorService } from 'src/app/services/contractor/contractor.service';
import { BusinessLineService } from 'src/app/services/businessLine/business-line.service';
import { DivisionInterface } from 'src/app/interfaces/division.interface';
import { DivisionService } from 'src/app/services/division/division.service';
import { AreaService } from 'src/app/services/area/area.service';
import { ActivityService } from 'src/app/services/activity/activity.service';
import { ResponsableService } from 'src/app/services/responsable/responsable.service';
import { CheckService } from 'src/app/services/check/check.service';
import { UtilService } from 'src/app/services/utils/util.service';
import { AreaInterface } from 'src/app/interfaces/area.interface';
import { ActivityInterface } from 'src/app/interfaces/activity.interface';
import { ContractInterface } from '../../interfaces/contract.interface';
import { MatAutocompleteSelectedEvent, MatDialog } from '@angular/material';
import { ResponsableInterface } from 'src/app/interfaces/responsable.interface';
import { AddExpedientInterface } from 'src/app/interfaces/addExpedient.interface';
import { ExpedientService } from 'src/app/services/expedient/expedient.service';
import { ExpedientDetailsInterface } from 'src/app/interfaces/expedientDetails.interface';
import { EditExpedientDto } from 'src/app/dto/editExpedient.dto';
import { CheckInterface } from 'src/app/interfaces/check.interface';
import { StatusInterface } from 'src/app/interfaces/status.interface';
import { Router } from '@angular/router';
import { AddContractorDialogComponent } from 'src/app/dialogs/add-contractor-dialog/add-contractor-dialog.component';
import { EmailResponseInterface } from 'src/app/interfaces/emailResponse.interface';
import { ContractorCreatedInterface } from 'src/app/interfaces/contractorCreated.interface';
import { AddResponsableDialogComponent } from 'src/app/dialogs/add-responsable-dialog/add-responsable-dialog.component';
import { IdContractorInterface } from 'src/app/interfaces/idContractors.interfa';
import { Subscription, forkJoin, Observable, UnsubscriptionError } from 'rxjs';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { pairwise, startWith, debounceTime } from 'rxjs/operators';
/* jalapont: DS2-T14819: añadir forma jurídica en el formulario de expedientes */
import { LegalFormInterface } from 'src/app/interfaces/legalForm.interface';
import { LegalFormService } from 'src/app/services/legalForm/legalForm.service';

@Component({
  selector: 'app-add-edit-expedient',
  templateUrl: './add-edit-expedient.component.html',
  styleUrls: ['./add-edit-expedient.component.scss']
})
export class AddEditExpedientComponent implements OnInit, OnDestroy {
  public isValid = false;
  public isDateValid = false;
  public bFilled = false;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  private bRemoved = false;
  public validLegalForm: boolean;
  public showLegalForm: boolean;
  public notAllowedCharOnCode: boolean;

  // Dropdown itemList
  public contractorList: ContractorInterface[] = [];
  public businessLineList: BusinessLineInterface[] = [];
  public divisionList: DivisionInterface[] = [];
  public areaList: AreaInterface[] = [];
  public activityList: ActivityInterface[] = [];
  public checkList: CheckInterface[] = [];
  public emailList: EmailResponseInterface[] = [];
  public legalFormList: LegalFormInterface[] = [];

  public selectedContractList: string[] = [];
  public selectedResponsableUb: ResponsableInterface[] = [];
  public selectedResponsableUcc: ResponsableInterface[] = [];
  public selectedResponsableSpm: ResponsableInterface[] = [];
  public selectedContractors: ContractorInterface[] = [];
  public selectedBusinessModel: BusinessLineInterface = <BusinessLineInterface>{};
  public selectedEmails: EmailResponseInterface[] = [];
  public selectedDivision: DivisionInterface = <DivisionInterface>{};
  public selectedAreas: AreaInterface[] = [];
  public selectedActivity: ActivityInterface = <ActivityInterface>{};
  public selectedLegalForm: LegalFormInterface = <LegalFormInterface>{};
  public selectedCheck: CheckInterface = <CheckInterface>{};
  public today = (new Date()).toISOString().split('T')[0];
  private subscriptions: Subscription[] = [];

  public allAreasSelected = false; // Lleva el control de la opción "Seleccionar / Deseleccionar todas" en el campo áreas
  public showAreas = false;
  public areasButtonVisible = false;
  public toggleButtonArea = true;
  public MAX_AREAS_VISIBLE = 3;
  //itemList

  //filter
  public filteredContracts: ContractInterface[];
  public filteredResponsableUb: ResponsableInterface[];
  public filteredResponsableUcc: ResponsableInterface[];
  public filteredResponsableSpm: ResponsableInterface[];
  //filter

  public form: FormGroup;
  @Input() expedientDetails: ExpedientDetailsInterface;
  @ViewChild('contractInput', { static: false }) contractInput: ElementRef<HTMLInputElement>;
  @ViewChild('responsableUbInput', { static: false }) respUbInput: ElementRef<HTMLInputElement>;
  @ViewChild('responsableUccInput', { static: false }) respUccInput: ElementRef<HTMLInputElement>;
  @ViewChild('responsableSpmInput', { static: false }) respSpmInput: ElementRef<HTMLInputElement>;

  constructor(
    private router: Router,
    private _expedient: ExpedientService,
    private _util: UtilService,
    private _division: DivisionService,
    private _area: AreaService,
    private _activity: ActivityService,
    private _responsable: ResponsableService,
    private _check: CheckService,
    private _contractor: ContractorService,
    public dialog: MatDialog,
    private _businessLine: BusinessLineService,
    private fb: FormBuilder,
    private _legalForm: LegalFormService /* jalapont: DS2-T14819: añadimos el servicio para rellenar las formas jurídicas */
  ) { }

  ngOnInit() {
    // Let's start creating the Form structure and its validators:
    this.form = this.createForm();
    console.log("ngOnInit - form: " + this.form);
    // Are we creating a new Expedient or updating an existent one?
    if (this.expedientDetails) {
      // We're updating an existent one. So fill the data:
      this.fillForm();
    } else {
      // We're about to fill a new Expedient.
      // To begin with, let's set the "Initial Value" to today:
      this.form.get('initialDate').setValue(this.today);
      this.bFilled = true;
    }

    // :::STOP HERE:::
    // Reactions to .valueChange() behavior of all the Controls:
    this.subscriptions.push(this.form.get('code').valueChanges
      .pipe(debounceTime(1200), startWith(null), pairwise()).subscribe(
        ([prev, actual]: [string, string]) => {
          // Trimming spaces, just in case:
          prev = prev ? prev.trim() : prev;
          actual = actual ? actual.trim() : actual;

          // If the User deleted the Code...
          if (actual === '' && prev) {
            //...Delete the MatChip associated.
            this.selectedContractList = this.selectedContractList.filter(e => e !== prev);
          }

          //If the input Code is exactly the same as the Code in the Expedient, we don't need to perform any request:
          if (this.expedientDetails && (actual === this.expedientDetails.code)) {
            if (this.selectedContractList.indexOf(actual) === -1) {
              // First add:
              this.selectedContractList.unshift(actual);
            }
            // Then Delete Previous value from the Matchip:
            this.selectedContractList = this.selectedContractList.filter(e => e !== prev);
          }

          //Avoid a request when:
          // 0) actual is empty or null.
          // 1) actual is the same as the previous
          // 2) in case we're modifying an expedient, its code is same as actual

          if (actual && (actual !== prev) && !(this.expedientDetails && (actual === this.expedientDetails.code))) {
            this.subscriptions.push(this._expedient.existsByCode(actual).subscribe((result) => {
              console.log(result);
              this.isValid = !result.status;
              // Check if the new Code is not used:
              if (this.isValid) {
                // Not used...
                // Check if Contract is not in the Array:
                if (this.selectedContractList.indexOf(actual) === -1) {
                  // Is the Contract already used in another Expedient:
                  this.subscriptions.push(this._expedient.existsByContract(actual.trim()).subscribe((result) => {
                    this.isValid = !result.status;
                    if (this.isValid) {
                      // It's unique. Add it.
                      this.selectedContractList.push(actual.trim());
                    } else {
                      // Sorry. It does exist. Alert about it!:
                      alert('El Código Administrativo " ' + actual + ' " ya está asociado a otro expediente aprobado o en curso por lo que no se añadirá automáticamente a la lista de Contratos');
                    }
                  }));
                }
                // Then Delete Previous value from the Matchip:
                this.selectedContractList = this.selectedContractList.filter(e => e !== prev);
              } else {
                // Delete Previous value from the Matchip:
                this.selectedContractList = this.selectedContractList.filter(e => e !== prev);
                alert('El código ya está asociado a un expediente');
                this.form.get('code').setValue(null);
              }
            }));
          }
        }
      ));
    this.subscriptions.push(this.form.get('finalDate').valueChanges
      .subscribe((fDate) => {
        let finDate: Date;
        let iniDate: Date;
        // Comprobar que el resultado del subscribe tiene algún valor
        if (fDate && fDate != null && fDate != undefined) {
          // Comprobar que el resultado recibido en el subscribe es de tipo Date,
          // en caso de no ser de tipo Date, crear variable de tipo Date
          // TODO En caso de recibir un string comprobar que tiene formato de fecha (regexp) y convertir a Date
          if (fDate instanceof Date) {
            finDate = fDate;
          } else {
            finDate = new Date(fDate);
          }

          // Recoger del formulario el valor de 'initialDate'
          iniDate = this.form.get('initialDate').value ? new Date(this.form.get('initialDate').value) : null;

          // En caso de que no exista 'initialDate' mostrar alert de error y setear 'finalDate' como null
          if (iniDate != null && finDate != null) {
            // Comprobar que la fecha final contiene un año con al menos 4 cifras
            if (finDate.getFullYear().toString().length >= 4) {
              // Comprobar que 'finalDate' es mayor que 'initialDate'
              if (finDate >= iniDate) {
                this.isDateValid = true;
              } else {
                alert('La fecha final no puede ser anterior a la fecha de inicio');
                this.isDateValid = false;
                if (this.expedientDetails && this.expedientDetails.final_date) {
                  this.form.get('finalDate').setValue(this.expedientDetails.final_date);
                } else {
                  this.form.get('finalDate').setValue(null);
                }
              }
            }
          } else {
            this.isDateValid = false;
          }
        } else {
          this.isDateValid = false;
        }
      }));
    this.subscriptions.push(this.form.get('initialDate').valueChanges
      .subscribe((iDate) => {
        let finDate: Date;
        let iniDate: Date;
        // Comprobar que el resultado del subscribe tiene algún valor
        if (iDate && iDate != null && iDate != undefined) {
          // Comprobar que el resultado recibido en el subscribe es de tipo Date,
          // en caso de no ser de tipo Date, crear variable de tipo Date
          // TODO En caso de recibir un string comprobar que tiene formato de fecha (regexp) y convertir a Date
          if (iDate instanceof Date) {
            iniDate = iDate;
          } else {
            iniDate = new Date(iDate);
          }

          // Recoger del formulario el valor de 'initialDate'
          finDate = this.form.get('finalDate').value ? new Date(this.form.get('finalDate').value) : null;

          // En caso de que no exista 'initialDate' mostrar alert de error y setear 'finalDate' como null
          if (iniDate != null && finDate != null) {
            // Comprobar que la fecha inicial contiene un año con al menos 4 cifras
            if (iniDate.getFullYear().toString().length >= 4) {
              // Comprobar que 'finalDate' es mayor que 'initialDate'
              if (finDate >= iniDate) {
                this.isDateValid = true;
              } else {
                alert('La fecha inicial no puede ser posterior a la fecha final');
                this.isDateValid = false;
                if (this.expedientDetails && this.expedientDetails.initial_date) {
                  this.form.get('initialDate').setValue(this.expedientDetails.initial_date);
                } else {
                  this.form.get('initialDate').setValue(null);
                }
              }
            }
          } else {
            this.isDateValid = false;
          }
        } else {
          this.isDateValid = false;
        }
      }));
    this.subscriptions.push(this.form.get('idContractors').valueChanges
      .subscribe(filterText => {
        if (filterText === '') {
          // Reset the value of the contractors selected:
          this.selectedContractors = [];
          // Clear Emails & legalform which depends on Contractors:
          this.selectedEmails = [];
          this.form.get('email').reset();
          this.form.get('legalForm').reset();
          // Clear Area dropdown list
          this.emailList = [];
        }
        if (filterText != undefined) {
          this.getContractorList(filterText);
        }
      })
    );
    this.subscriptions.push(this.form.get('contracts').valueChanges
      .subscribe(filterText => {
      })
    );
    this.subscriptions.push(this.form.get('idTypeChecks').valueChanges
      .subscribe(filterText => {
        console.log('VALUE CHANGES TYPECHECK');
        console.log(filterText);
        // TODO Diferenciar si filterText es un object o es un string
        if (!(typeof filterText === 'object')) {
          this.getChecks(filterText);
        }
      })
    );
    this.subscriptions.push(this.form.get('idResponsablesUb').valueChanges
      .subscribe(filterText => {
        if ((filterText !== '') && (filterText !== null) && (filterText !== undefined) && (filterText.length > 2)) {
          this.getResponsableUb(filterText);
        } else {
          // If empty or null: Reset the Dropdown list
          this.filteredResponsableUb = [];
        }
      })
    );
    this.subscriptions.push(this.form.get('idResponsablesUcc').valueChanges
      .subscribe(filterText => {
        if ((filterText !== '') && (filterText !== null) && (filterText !== undefined) && (filterText.length > 2)) {
          this.getResponsableUcc(filterText);
        } else {
          // If empty or null: Reset the Dropdown list
          this.filteredResponsableUcc = [];
        }

      })
    );
    this.subscriptions.push(this.form.get('idResponsablesSpm').valueChanges
      .subscribe(filterText => {
        if ((filterText !== '') && (filterText !== null) && (filterText !== undefined) && (filterText.length > 2)) {
          this.getResponsableSpm(filterText);
        } else {
          // If empty or null: Reset the Dropdown list
          this.filteredResponsableSpm = [];
        }
      })
    );
    this.subscriptions.push(this.form.get('idDivision').valueChanges
      .subscribe(filterText => {
        if (filterText === '') {
          // Clear Division:
          this.selectedDivision = <DivisionInterface>{};
          // Clear idArea input value which depends on Division:
          this.form.get('idArea').reset();
          // Clear Area dropdown list
          this.areaList = [];
        }
        if (filterText != undefined) {
          this.getDivision(filterText);
        }
      }));
    this.subscriptions.push(this.form.get('idBusinessModel').valueChanges
      .subscribe(filterText => {
        // If User deleted all the characters.
        if (filterText === '') {
          //Clear BusinessModel
          this.selectedBusinessModel = <BusinessLineInterface>{};
          //Clear Division, Activity, TypeChecks, Contractors and Emails which depends on the BusinessModel:
          this.form.get('idContractors').reset();
          this.form.get('idActivity').reset();
          this.form.get('idTypeChecks').reset();
          this.form.get('idDivision').reset();
          this.form.get('idArea').reset();
          this.form.get('email').reset();
          this.form.get('legalForm').reset();
          this.form.get('idResponsablesUb').reset();
          //Dropdown Lists should be cleared:
          this.contractorList = [];
          this.activityList = [];
          this.checkList = [];
          this.divisionList = [];
          this.areaList = [];
          this.emailList = [];
          this.filteredResponsableUb = [];
          //Edit/Update needs to reset the values of its respective selected:
          this.selectedContractors = [];
          this.selectedActivity = <ActivityInterface>{};
          this.selectedCheck = <CheckInterface>{};
          this.selectedDivision = <DivisionInterface>{};
          this.selectedAreas = [];
          this.selectedEmails = [];
          this.selectedResponsableUb = [];
          this.selectedLegalForm = <LegalFormInterface>{};
        }
        // If user is typing or deleting:
        if (filterText !== undefined) {
          this.getBusinessLineList(filterText)
        }
      }));
    this.subscriptions.push(this.form.get('idArea').valueChanges
      .subscribe(filterText => {
        if (filterText != undefined) {
          this.getArea(filterText)
        }
      }));
    this.subscriptions.push(this.form.get('idActivity').valueChanges
      .subscribe(filterText => {
        if (filterText != undefined) {
          this.getActivities(filterText)
        }
      }));
    this.subscriptions.push(this.form.get('email').valueChanges
      .subscribe(filterText => {
        if (filterText != undefined) {
           if (this.selectedContractors[0].sid) {
              this.getEmailList(this.selectedContractors[0].sid,filterText);
           }
    //      if (this.selectedContractors[0].sid) {
    //        this.getAllOfficialEmails(this.selectedContractors[0].sid);
    //      } else {
    //        this.getAllNoOfficialEmails(this.selectedContractors[0].id);
    //      }
        }
    }));
    
  }

  private fillForm() {
    console.log("fillForm - campos: " + JSON.stringify(this.form.value));
    
    // Let's start requesting data to the Server based in the expedientDetails.id:
    this.subscriptions.push(forkJoin(
      [this._expedient.getTypeCheck(this.expedientDetails.id),
      this._expedient.getContractorsExpedient(this.expedientDetails.id),
      this._expedient.getExpedientEmailsList(this.expedientDetails.id),
      this._expedient.getContractsExpedient(this.expedientDetails.id)
      ]).subscribe(
        data => {
          const type = data[0];
          const contractors = data[1];
          const emails = data[2];
          const contracts = data[3];

          this.form.get('idTypeChecks').setValue({ id: this.expedientDetails.id_type_check, name: type.document + ': ' + type.name });
          this.form.get('idContractors').setValue(contractors[0]);
          console.log("fillForm -  contractor: " + JSON.stringify(contractors[0]));
          this.selectedEmails = [...emails];
          this.selectedContractList = contracts.map(contract => contract.name);
        },
        err => { console.log(err) },
        () => {
          console.log("fillForm - expedientDetails: " + JSON.stringify(this.expedientDetails));
          // Set values to the Controls in the Form.
          // Check previous calls to find Controls being set via API.
          this.form.get('code').setValue(this.expedientDetails.code.toString());
          this.form.get('title').setValue(this.expedientDetails.title.toString());
          this.form.get('initialDate').setValue(this.expedientDetails.initial_date);
          this.form.get('finalDate').setValue(this.expedientDetails.final_date);
          this.form.get('idDivision').setValue(this.expedientDetails.id_division);
          this.form.get('idActivity').setValue(this.expedientDetails.id_activity);
          this.form.get('idBusinessModel').setValue(this.expedientDetails.id_business_model);
          this.form.get('has_access').setValue(this.expedientDetails.has_access ? "yes" : "no");
          // Make a copy of the content expedientdetails.id_contractors and save it in selectedContractors:
          this.isValid = true;
          this.isDateValid = true;
          this.selectedContractors = this.expedientDetails.id_contractors.slice();
          console.log("legalForm: " + JSON.stringify(this.expedientDetails.idLegalForm));
          this.initializeLegalForm(this.expedientDetails.idLegalForm);
          this.selectedAreas = this.expedientDetails.id_area.slice();
          this.selectedDivision = this.expedientDetails.id_division;
          this.selectedActivity = this.expedientDetails.id_activity;
          this.selectedCheck = this.expedientDetails.id_type_check;
          this.selectedBusinessModel = this.expedientDetails.id_business_model;
          this.selectedResponsableSpm = this.expedientDetails.id_responsables_spm.slice();
          this.selectedResponsableUb = this.expedientDetails.id_responsables_ub.slice();
          this.selectedResponsableUcc = this.expedientDetails.id_responsables_ucc.slice();
          this.bFilled = true;
        }
      ));
  }

  // #### Common Methods: Create and Update ####
  // On Key Enter:
  public addContract($event) {
    const newContract = $event.value;
    const input = $event.input;
    if ((newContract || '').trim()) {
      // Is Contract is already added:
      if (this.selectedContractList && (this.selectedContractList.indexOf(newContract.trim()) === -1)) {
        // No...so is it already being used by other Expedient?
        this.subscriptions.push(this._expedient.existsByContract(newContract.trim()).subscribe((result) => {
          console.log(result);
          this.isValid = !result.status;
          if (this.isValid) {
            // It's unique. Add it.
            this.selectedContractList.push(newContract.trim());
          } else {
            // Sorry. It does exist. Alert about it!:
            alert('El contrato ya está asociado a un expediente');
          }
        }));
      }
    }
    // Reset the input area:
    if (input) {
      input.value = '';
    }
  }
  // Called by: ngOnInit()
  private createForm() {
    return this.fb.group({
      code: [null, Validators.compose([Validators.required])],
      title: [null, Validators.compose([Validators.required])],
      idContractors: [null, Validators.compose([Validators.required])],
      idBusinessModel: [null, Validators.compose([Validators.required])],
      idDivision: [null, Validators.compose([Validators.required])],
      idArea: [null],
      idActivity: [null, Validators.compose([Validators.required])],
      contracts: [null],
      legalForm: [null],
      idTypeChecks: [null, Validators.compose([Validators.required])],
      idResponsablesUb: [null],
      idResponsablesUcc: [null],
      idResponsablesSpm: [null],
      initialDate: [null, Validators.compose([Validators.required])],
      finalDate: [null, Validators.compose([Validators.required])],
      email: [null],
      has_access: [null, Validators.compose([Validators.required])]
    });
  }
  // ::: Helpers for "valueChanges()" :::
  private getContractorList(filterText: string) {
    if (filterText) {
      if (filterText.length > 2) {
        console.log("getContractorList: selectedBM " + JSON.stringify(this.selectedBusinessModel));
        // jalapont: DS2-T14819 - Alternativa necesaria si el SID de la Línea de negocio es 'undefined' 
        if(this.selectedBusinessModel.sid == undefined){
          this.initializeBusinessLineAndGetContractorList(this.selectedBusinessModel.id, filterText);
        } else {
          this.subscriptions.push(this._contractor.getContractorListFilterBy(this.selectedBusinessModel.id,
            filterText, this.selectedBusinessModel.sid).subscribe(contractListReceived => {
            if (contractListReceived) {
              this.contractorList = contractListReceived;
            }
          }));
        }
      } else {
        this.contractorList = [];
      }
    }
  }

  /* jalapont: DS2-T14819 - Inicializa la Línea de negocio y recupera la lista de contratistas para esa Línea de negocio 
  *  Sólo se invoca cuando businessLine es undefined en getContractorList 
  */
  private initializeBusinessLineAndGetContractorList(businessLineId: number, filterText: string) {
    this.businessLineList = null;
    this.subscriptions.push(this._businessLine.getAllBusinessLine('').subscribe(businessLineListReceived => {
      this.businessLineList = businessLineListReceived;
      this.businessLineList.forEach( (element) => {
        if(businessLineId == element.id){
          this.selectedBusinessModel = element;
          console.log("initializeBusinessLineAndGetContractorList: encontrado elemento");
          console.log(JSON.stringify(this.selectedBusinessModel));

          //Buscamos la lista de contratistas (de un elemento), pasándole el SID de la Línea de Negocio recuperado antes
          this.subscriptions.push(this._contractor.getContractorListFilterBy(this.selectedBusinessModel.id,
            filterText, this.selectedBusinessModel.sid).subscribe(contractListReceived => {
            if (contractListReceived) {
              this.contractorList = contractListReceived;
            }
          }));
        }
      });
    }));
  }

  private getEmailList(idContractor: number,filterText: string) {
    if (filterText) {
      if (filterText.length > 2) {
        this.subscriptions.push(this._contractor.getOfficialEmailsFilterBy(idContractor, filterText).subscribe(emailListReceived => {
           if (emailListReceived) {
             this.emailList = emailListReceived;
           }
        }));
      } else {
        this.emailList = [];
      }
    }
  }
   
  private getChecks(filterText: string) {
    this.checkList = null;
    this.subscriptions.push(this._check.getAllChecks(this.selectedBusinessModel.id, filterText).subscribe(list => {
      console.log(list);
      this.checkList = list;
    }));
  }

  private getBusinessLineList(filterText: string) {
    this.businessLineList = null;
    this.subscriptions.push(this._businessLine.getAllBusinessLine(filterText).subscribe(businessLineListReceived => {
      this.businessLineList = businessLineListReceived;
    }));
  }

  private getDivision(filterText: string) {
    this.subscriptions.push(this._division.getAllDivisions(this.selectedBusinessModel.id, filterText).subscribe(divisionListReceived => {
      this.divisionList = divisionListReceived;
    }));
  }

  private getArea(filterText: string) {
    this.subscriptions.push(this._area.getAllAreas(this.selectedDivision.id, filterText).subscribe(areaListReceived => {
      this.areaList = areaListReceived;
      // jalapont: comprobamos que la lista de áreas original y la lista de seleccionadas es diferente para modificar la opción Select All
      if(this.areaList.length != this.selectedAreas.length){
        this.allAreasSelected = false;
      } else {
        this.allAreasSelected = true;
      }
    }));
  }

  private getActivities(filterText: string) {
    this.subscriptions.push(this._activity.getAllActivities(this.selectedBusinessModel.id, filterText).subscribe(activityListReceived => {
      this.activityList = activityListReceived;
    }));
  }

  private getResponsableUb(filterText: string) {
    if (filterText !== undefined && filterText !== null && filterText !== '' && !this.startsWithObj(filterText)) {
      this.subscriptions.push(this._responsable
        .getAllResponsableUb(filterText, this.selectedBusinessModel.id)
        .subscribe(responsableList => {
          this.filteredResponsableUb = responsableList;
        })
      );
    }
  }

  private getResponsableUcc(filterText: string) {
    if (filterText !== undefined && filterText !== null && filterText !== '' && !this.startsWithObj(filterText)) {
      this.subscriptions.push(this._responsable.getAllResponsableUcc(filterText).subscribe(responsableList => {
        this.filteredResponsableUcc = responsableList;
      }));
    }
  }

  private getResponsableSpm(filterText: string) {
    if (filterText !== undefined && filterText !== null && filterText !== '' && !this.startsWithObj(filterText)) {
      this.subscriptions.push(this._responsable.getAllResponsableSpm(filterText).subscribe(responsableList => {
        this.filteredResponsableSpm = responsableList;
      }));
    }
  }

  // Called by getResponsableSpm(), getResponsableUCC(), getResponsableUB()
  private startsWithObj(text: string): boolean {
    const ob: string = '[object'.toString();
    const t: string = text.toString();
    return t.startsWith(ob);
  }

  // === Mat-autocomplete (optionSelected) ===
  public onSelected(formControl: AbstractControl, arraySelected: any[], event: MatAutocompleteSelectedEvent): void {
    let bExists = false;
    if (event.option.value) {

      //Check if the item selected exist already in the Selected list
      if (event.option.value.id) {
        //
        if (arraySelected.some(item => item.id === event.option.value.id)) {
          bExists = true;
        } else {
          bExists = false;
        }
      }

      if (event.option.value.sid) {
        if (arraySelected.some(item => item.sid === event.option.value.sid)) {
          bExists = true;
        } else {
          bExists = false;
        }
      }

      //If the item is not found in the Selected List:
      if (!bExists) {
        //Push to the list
        arraySelected.push(event.option.value);
        this.contractInput.nativeElement.value = '';
        if (this.respUbInput) {
          this.respUbInput.nativeElement.value = '';
        }
        this.respUccInput.nativeElement.value = '';
        this.respSpmInput.nativeElement.value = '';
      }
      formControl.setValue(null);
      formControl.disable();
      formControl.enable();
    }

  }

  /** jalapont:
    * Seleccionar/Deseleccionar elemento área en la lista. Es similar a onSelected,
    * pero borra el elemento seleccionado de la lista de arraySelected si ya estaba checkeado
    *
    * @param formControl control del campo área
    * @param arraySelected array de áreas seleccionadas
    * @param event evento que contiene la opción seleccionada
    * */
  public onSelectedArea(formControl: AbstractControl, arraySelected: any[], event: MatAutocompleteSelectedEvent): void {
    let bExists = false;

    if (event.option.value) {
      //Check if the item selected exist already in the Selected list
      if (event.option.value.id) {
        //
        if (arraySelected.some(item => item.id === event.option.value.id)) {
          bExists = true;
        } else {
          bExists = false;
        }
      }

      if (event.option.value.sid) {
        if (arraySelected.some(item => item.sid === event.option.value.sid)) {
          bExists = true;
        } else {
          bExists = false;
        }
      }

      //If the item is not found in the Selected List:
      if (!bExists) {
        //Push to the list
        arraySelected.push(event.option.value);
        this.contractInput.nativeElement.value = '';
        if (this.respUbInput) {
          this.respUbInput.nativeElement.value = '';
        }
        this.respUccInput.nativeElement.value = '';
        this.respSpmInput.nativeElement.value = '';
      } else {
        let index = arraySelected.findIndex((element) => element.id == event.option.value.id);
        if(index>=0){
          arraySelected.splice(index, 1);
        }
      }

      formControl.setValue(null);
      formControl.disable();
      formControl.enable();
    }
    if(this.selectedAreas.length != this.areaList.length){
      this.allAreasSelected = false;
    }
    if(this.selectedAreas.length > this.MAX_AREAS_VISIBLE){
      this.areasButtonVisible = true;
    }else{
      this.areasButtonVisible = false;
    }
  }
  
  /** jalapont:
    * Determinar si un área está o no seleccionada y marcar su checkbox.
    *
    * @param area control del campo área
    * @param arraySelected array de áreas seleccionadas
    * @return boolean;
    * */
  public isAreaSelected(area: AreaInterface, arraySelected: any[]): boolean {
    let bExists = false;
    
    if (arraySelected.some(item => item.id === area.id)) {
        bExists = true;
    } else {
        bExists = false;
    }
    return bExists;
  }

  /** jalapont:
    * Añadir todos los elementos de la lista a la selección.
    * Utilizado al pulsar la opción "Seleccionar / Deseleccionar todas" en el campo áreas
    * */
  public checkAllAreas(){
    if(this.selectedAreas.length == this.areaList.length){
      this.selectedAreas = [];
      this.allAreasSelected = false;
      this.areasButtonVisible = false;
    } else {
      this.selectedAreas = [];
      this.areaList.forEach( (element) => {
        this.selectedAreas.push(element);
      });
      this.allAreasSelected = true;
      if(this.selectedAreas.length > this.MAX_AREAS_VISIBLE){
        this.areasButtonVisible = true;
      } else {
        this.areasButtonVisible = false;
      }
    }
  }

  public showAllAreas(){
    if(!this.showAreas){
      this.showAreas = true;
    }else{
      this.showAreas = false;
    }  
  }

  public onSelectedBusinessModel(oneBLine: BusinessLineInterface) {
    if (oneBLine !== null || oneBLine !== undefined) {
      //Set the Business Line
      this.selectedBusinessModel = oneBLine;

      //Reset all the Lists:
      this.divisionList = null;
      this.activityList = null;
      this.areaList = null;
      this.contractorList = null;
      this.divisionList = null;
      this.checkList = null;
      this.emailList = null;
      this.selectedResponsableUb = [];
      //Reset Division:
      this.selectedDivision = <DivisionInterface>{};
      this.selectedEmails = [];

      // A new business line has been selected, so reset the fields depending on it:
      this.form.get('idTypeChecks').reset();
      this.form.get('idDivision').reset();
      this.form.get('idActivity').reset();
      this.form.get('idArea').reset();
      this.form.get('idContractors').reset();


      this.subscriptions.push(this._division.getAllDivisions(oneBLine.id, '').subscribe(divisionListReceived => {
        this.divisionList = divisionListReceived;
        this.form.get('idDivision').enable();
      }, err => {
        console.log(err);
      }));

      this.subscriptions.push(this._activity.getAllActivities(oneBLine.id, '').subscribe(activityListReceived => {
        this.activityList = activityListReceived;
        this.form.get('idActivity').enable();
      }, err => {
        console.log(err);
      }));

      this.subscriptions.push(this._check.getAllChecks(oneBLine.id, '').subscribe(checkListReceived => {
        this.checkList = checkListReceived;
        this.form.get('idActivity').enable();
      }, err => {
        console.log(err);
      }));

    } else {
      console.log('Business Line undefined');
    }
  }
  public onSelectedContractor(contractor: any) {
    // An item of the listbox is selected
    if (contractor instanceof Object) {
      this.form.get('email').reset();
      this.selectedEmails = new Array();
      //TODO de momento se resetea el array porque solo se puede seleccionar uno
      // this.selectedContractors = new Array();
      this.selectedContractors[0] = contractor;
      if (contractor.sid) {
        this.getAllOfficialEmails(contractor.sid);
      } else {
        this.getAllNoOfficialEmails(contractor.sid);
      }
      // jalapont: DS2-T14819 - Comprobamos que el contratista esté asociado con la Línea de negocio (cliente). Si lo está, no muestro la Razón jurídica
      console.log("¿Existe relación entre Contratista y Línea de negocio? " + contractor.existsInInstance);
      if(contractor.existsInInstance){
        this.showLegalForm = false;
        this.form.get('legalForm').reset();
        this.selectedLegalForm = null;
      }else{
        this.showLegalForm = true;
      }
    } else {
      // The user clicked in "Añadir nuevo Contratista"
      const dialogRef = this.dialog.open(
        AddContractorDialogComponent,
        {
          panelClass: 'container',
          data: { 
            name: contractor
          }
        });
      this.subscriptions.push(dialogRef.afterClosed().subscribe((contractorNoOfficial: ContractorCreatedInterface) => {
        if (contractorNoOfficial) {
          this.selectedEmails = new Array();
          this.emailList = new Array();
          console.log("contractor-created-int: " + contractorNoOfficial.description);
          this.selectedContractors[0] = {
            sid: contractorNoOfficial.sid,
            code: contractorNoOfficial.code,
            name: contractorNoOfficial.description,
            existsInInstance: false
          };
          this.form.get('idContractors').setValue(contractorNoOfficial);
          this.showLegalForm = true;
        }
      }));
    }
  }
  public onSelectedEmail(event: MatAutocompleteSelectedEvent) {
    console.log(event);
    console.log(typeof event.option.value);
    // IF An item of the listbox is selected
    if (event.option.value instanceof Object) {
      //THEN Call the regular: selected method
      this.onSelected(this.form.controls.email, this.selectedEmails, event);
    } else {
      // OTHERWISE: The user clicked in "Añadir nuevo email"
      const dialogRef = this.dialog.open(AddResponsableDialogComponent, {
        panelClass: 'container',
        data: { email: event.option.value, contractor: this.selectedContractors[0] }
      });
      this.subscriptions.push(dialogRef.afterClosed().subscribe((emailCreated: EmailResponseInterface) => {
        if (emailCreated && emailCreated !== null) {
          this.emailList.push(emailCreated);
          this.selectedEmails.push(emailCreated);

        }
      }));
    }
    this.form.get('email').setValue('');
  }

  // === Mat-autocomplete (displayWith) ===
  public displayFn(subject: any) {
    if (subject) {
      if (subject.name) {
        return subject.name;
      } else {
        return subject.company;
      }
    } else {
      return undefined;
    }
  }

  /* jalapont: RMS#5399 - Modificada forma en la que se muestra el contratista */
  public displayContractorFn(subject: any) {
    console.log("displayContractorFn: " + JSON.stringify(subject));
    if (subject) {
      console.log("subject.desc: "+subject.desc);
      console.log("subject.name: "+subject.name);
      console.log("subject.description: "+subject.description);
      let displayContractorValue;
      if(subject.desc != null){
        displayContractorValue = subject.desc + ' ( ' + subject.code + ' )';
      }else if(subject.description != null){
        displayContractorValue = subject.description + ' ( ' + subject.code + ' )';
      }else if(subject.name != null){
        displayContractorValue = subject.name + ' ( ' + subject.code + ' )';
      }else{
        displayContractorValue = subject;
      }
      return displayContractorValue;
    } else {
      return undefined;
    }
  }
  // == Helpers onSelected*() ==

  private getAllNoOfficialEmails(idContractor: number) {
    this.subscriptions.push(this._contractor.getAllNoOfficialEmails(idContractor).subscribe((noOfficialEmailList: EmailResponseInterface[]) => {
      this.emailList = noOfficialEmailList;
    }, error => {
      console.log(error);
    }));
  }
  private getAllOfficialEmails(idContractor: number) {
    this.subscriptions.push(this._contractor.getAllOfficialEmails(idContractor).subscribe((emails: EmailResponseInterface[]) => {
      this.emailList = emails;
    }, error => {
      console.log(error);
    }));
  }

  // === Mat-chips removal ===
  public onRemove(itemArray: any[], item: any): void {
    /* jalapont: el contrato no debe ser borrado si tiene el mismo código que el código del expediente */
    if(item === this.form.get('code').value){
      return;
    }
	
    //Mark as removed:
    this.bRemoved = true;
    const index = itemArray.indexOf(item);
    if (index >= 0) {
      itemArray.splice(index, 1);
    }

    // Añadir control para mostrar botón de número máximo en áreas
    if(this.selectedAreas.length > this.MAX_AREAS_VISIBLE){
      this.areasButtonVisible = true;
    }else{
      this.areasButtonVisible = false;
    }
  }

  // === Mat-option on "Division":
  public onSelectDivision(division: DivisionInterface) {
    this.selectedDivision = division;
    this.selectedAreas = [];
    this.form.get('idArea').reset();
    this.form.get('idArea').enable();
  }

  /* jalapont: RMS#5399 - se revisa método para que compare correctamente tras los nuevos mapeos */
  public bContractorExists(newContractor: string) {
    let bExists = false;
    if (this.contractorList) {
      bExists = this.contractorList.some(contractor => {
        if(contractor.name === newContractor || contractor.desc === newContractor){
          return true;
        }
      })
    }
    return bExists;
  }
  public bEmailExists(newEmail: string) {
    if (this.emailList) {
      return this.emailList.some(email => email.email === newEmail);
    }
  }

  // LazyLoad functions are created to populate the needed lists
  // when clicking its <input> the first time!
  // Param: Value of the <input> element
  // Description:
  //    If Param is '' then call the getApi to retrieve a list of
  //    If Param is not '' then don't call anyone.
  // NOTE: Executed just when they're first clicked.
  // NOTE2: Changes when keypressing are managed in their .valueChanges()
  public lazyLoadArea(value: string) {
    value === null || value === undefined ? this.getArea('') : null;
  }
  public lazyLoadBusinessModel(value: string) {
    if (!value && (this.businessLineList.length === 0)) {
      this.getBusinessLineList('');
    }
  }
  public lazyLoadCheck(value: string) {
    if (!value && (this.checkList.length === 0)) {
      this.getChecks('');
    }
  }
  public lazyLoadActivity(value: string) {
    if (!value && (this.activityList.length === 0)) {
      this.getActivities('');
    }
  }
  public lazyLoadEmails(value: string) {
    if (!value && (this.emailList.length === 0)) {
      if (this.selectedContractors[0].sid) {
        this.getAllOfficialEmails(this.selectedContractors[0].sid);
      } else {
        this.getAllNoOfficialEmails(this.selectedContractors[0].id);
      }
    }
  }

  // ##### Methods: Create ####
  // Executed when clicking in "Enviar solicitud"
  public onNewExpedient() {
    let newExpedient: AddExpedientInterface = {};

    // Fill newExpedient with the Form values
    newExpedient.has_access = this.form.get('has_access').value.toString().toLowerCase();
    newExpedient.initialDate = this.form.get('initialDate').value;
    newExpedient.finalDate = this.form.get('finalDate').value;
    newExpedient.title = this.form.get('title').value;
    newExpedient.code = this.form.get('code').value;

    // Add to newExpedient the values stored in the auxiliar arrays:
    newExpedient.idBusinessModel = this.selectedBusinessModel.id;
    newExpedient.idDivision = this.selectedDivision.id;
    newExpedient.idArea = this.selectedAreas;
    newExpedient.idActivity = this.selectedActivity.id;
    newExpedient.type_check = this.selectedCheck.id;
    newExpedient.idContractors = this.getContractors();
    newExpedient.contracts = this.selectedContractList;
    newExpedient.idResponsablesUcc = this.selectedResponsableUcc;
    newExpedient.idResponsablesUb = this.selectedResponsableUb;
    newExpedient.idResponsablesSpm = this.selectedResponsableSpm;
    if(this.selectedLegalForm !==null){
      newExpedient.idLegalForm = this.selectedLegalForm.sid; // jalapont: DS2-T14819 - Enviamos SID de Forma Jurídica
    } else{
      newExpedient.idLegalForm = null;
    }
    // Post newExpedient
    this.subscriptions.push(this._expedient.addExpedient(newExpedient).subscribe(created => {
      alert('Expediente creado con éxito.');
      this.router.navigate(['/home']);
    }));
  }
  // Called by: onNewExpedient()
  private getContractors(): IdContractorInterface[] {

    let contractors: IdContractorInterface[] = new Array();
    console.log("getContractors: contratista que se envía en newExpedient - " + JSON.stringify(this.selectedContractors[0]));
    // jalapont: el nombre del contratista puede ir en name o en desc; si no es uno, mandamos el otro
    var contractorName = this.selectedContractors[0].name;
    if(contractorName == null || contractorName == undefined){
      contractorName = this.selectedContractors[0].desc;
    }

    if (this.selectedContractors[0].sid) {
      contractors[0] = {
        sid: this.selectedContractors[0].sid,
        code: this.selectedContractors[0].code,
        name: contractorName,
        responsables: { id: [], sid: [] }
      };
    } else if (this.selectedContractors[0].id) {
      contractors[0] = {
        id: this.selectedContractors[0].id,
        code: this.selectedContractors[0].code,
        name: contractorName,
        responsables: { id: [], sid: [] }
      };
    }

    for (let i = 0; i < this.selectedEmails.length; i++) {
      if (this.selectedEmails[i].sid) {
        contractors[0].responsables.sid.push(this.selectedEmails[i].sid);
      } else if (this.selectedEmails[i].id) {
        contractors[0].responsables.id.push(this.selectedEmails[i].id);
      }
    }

    return contractors;
  }

  /* jalapont: RMS #5400 - carga de formas jurídicas */
  public lazyLoadLegalForms(filterText: string) {
    this.legalFormList = null;
    this.subscriptions.push(this._legalForm.getAllLegalForms(filterText).subscribe(legalFormListReceived => {
      this.legalFormList = legalFormListReceived;
      console.log("lazyLoadLegalForms: "+ JSON.stringify(this.legalFormList));
    }));
   
  }
  /* jalapont: DS2-T14819 - Inicializamos valor de Forma Jurídica en caso de estar editando un Expediente */
  public initializeLegalForm(legalFormSid: number) {
    this.legalFormList = null;
    this.subscriptions.push(this._legalForm.getAllLegalForms('').subscribe(legalFormListReceived => {
      this.legalFormList = legalFormListReceived;
      console.log("initializeLegalForm: "+ JSON.stringify(this.legalFormList));
      var keepInLoop = true;
      this.legalFormList.forEach(legalForm => {
        if(keepInLoop){
          // Si el SID de la Forma Jurídica coincide con el del elemento de la lista, recuperamos el elemento y lo marcamos como 
          // Forma Jurídica seleccionada. 
          if(legalForm.sid == legalFormSid){
            this.selectedLegalForm = legalForm;
            this.form.get('legalForm').setValue(legalForm);
            this.showLegalForm = true;
            keepInLoop = false; //forzamos salida del forEach
            return;
          } else{
            this.showLegalForm = false;
            this.form.get('legalForm').reset();
            this.selectedLegalForm = null;
          }
        }
      });      
    }));
  }

  // jalapont: RMS #5400 - al seleccionar elemento en Formas jurídicas 
  public onSelectedLegalForm(lForm: LegalFormInterface) {
    if (lForm !== null || lForm !== undefined) {
      //Set the Legal Form
      this.selectedLegalForm = lForm;
      console.log("selected " + this.selectedLegalForm);
    }
  }
  
  // jalapont: RMS #5400 - determinar cómo se muestran las Formas jurídicas
  public displayLegalFormsFn(subject: any) {
    if (subject) {
      if(subject.desc){
        return subject.desc //rellena el input con la descripción por defecto
      }else {
        return subject.code; //si no tiene descripción, rellénalo con el código
      }
    } else {
      return undefined;
    }
  }

  /* jalapont: RMS #5400 - comprueba que el valor del Input sea de la lista
    - Esto fuerza al usuario a que elija un valor y no pueda poner cualquier cosa
  */
  public checkLegalForm() {
    if (this.form.get('legalForm').value !== null && this.form.get('legalForm').value !== undefined 
        && !(this.form.get('legalForm').value instanceof Object)) {
        this.selectedLegalForm = null;
        this.form.get('legalForm').setValue(null);
        this.validLegalForm = false;
    }else{
        this.validLegalForm = true;
    }
  }
  
  /* No se permiten los siguientes caracteres en el campo Código de Expediente:
    · El punto "." (190)
    · La barra "/" (111 y shift + 55)  
  */
  public keyDownOnExpCode(event) {
    this.notAllowedCharOnCode = false; 
    if(event.which != 190 && event.which != 111 && !(event.shiftKey && event.which == 55)){
      this.notAllowedCharOnCode = false;  
      return true;
    }
    else {
      this.notAllowedCharOnCode = true;
      return false;
    }
  }
    
  // ##### Methods: Update ####
  // Executed when clicking in "Guardar"
  public disableSave() {
    //Form not valid: Disable Save
    //Empty arrays: Disable Save

    // jalapont: DS2-T14881 - en caso de ser necesaria la Forma jurídica, no permitir enviar expediente salvo que esté informada
    if(this.showLegalForm){
      return !(this.form.valid && this.isValid && this.isDateValid &&
        this.selectedAreas.length > 0 && this.selectedResponsableUb.length > 0 && this.selectedResponsableSpm.length > 0 &&
        this.selectedContractList.length > 0 && this.selectedResponsableUcc.length > 0 && 
        this.selectedLegalForm !== null && this.selectedLegalForm.sid !== undefined &&
        this.selectedContractors.length > 0 && this.selectedEmails.length > 0 &&
        // Check if there was interaction with the Form, or at least a Chip was removed:
        (this.form.dirty || this.bRemoved));
    }else{
        return !(this.form.valid && this.isValid && this.isDateValid &&
          this.selectedAreas.length > 0 && this.selectedResponsableUb.length > 0 && this.selectedResponsableSpm.length > 0 &&
          this.selectedContractList.length > 0 && this.selectedResponsableUcc.length > 0 && 
          this.selectedContractors.length > 0 && this.selectedEmails.length > 0 &&
          // Check if there was interaction with the Form, or at least a Chip was removed:
          (this.form.dirty || this.bRemoved));
    }
  }
  public onUpdateExpedient() {
    let has_access: string;
    let objectIsEmpty: boolean = true;
    let contractors: StatusInterface[] = new Array();
    let responsables: StatusInterface[] = new Array();
    let areas: StatusInterface[] = new Array();
    let contracts: StatusInterface[] = new Array();
    let rSpm: StatusInterface[] = new Array();
    let rUb: StatusInterface[] = new Array();
    let rUcc: StatusInterface[] = new Array();
    let newExpedient: EditExpedientDto = new EditExpedientDto();

    // Update values modified directly from the Form fields.
    if (this.form.get('code').dirty) {
      newExpedient.code = this.form.get('code').value;
      objectIsEmpty = false;
    }
    if (this.form.get('title').dirty) {
      newExpedient.title = this.form.get('title').value;
      objectIsEmpty = false;
    }
    if (this.form.get('has_access').dirty) {
      newExpedient.has_access = this.form.get('has_access').value.toString().toLowerCase();
      objectIsEmpty = false;
    }
    if (this.form.get('initialDate').dirty) {
      newExpedient.initialDate = this.form.get('initialDate').value;
      objectIsEmpty = false;
    }
    if (this.form.get('finalDate').dirty) {
      newExpedient.finalDate = this.form.get('finalDate').value;
      objectIsEmpty = false;
    }
    if (this.form.get('idActivity').dirty) {
      newExpedient.idActivity = this.selectedActivity.id;
      objectIsEmpty = false;
    }
    if (this.form.get('idBusinessModel').dirty) {
      newExpedient.idBusinessModel = this.selectedBusinessModel.id;
      objectIsEmpty = false;
    }
    
    // jalapont: DS2-T14819 - Comprobamos que la forma legal se esté mostrando (en caso negativo, debe guardarse como NULL)
    if(!this.showLegalForm){
      console.log("!show");
      newExpedient.id_legalForm = null;
    } else {
      console.log("show");
      console.log("this.selectedLegalForm.sid: "+this.selectedLegalForm.sid);
      newExpedient.id_legalForm = this.selectedLegalForm.sid;
      objectIsEmpty = false;
    }
    
    if (this.form.get('idDivision').dirty) {
      newExpedient.idDivision = this.selectedDivision.id;
      objectIsEmpty = false;
    }
    // if (this.form.get('idArea').dirty) {
    //   newExpedient.idArea = this.selectedArea.id;
    //   objectIsEmpty = false;
    // }
    if (this.form.get('idTypeChecks').dirty) {
      newExpedient.type_check = this.selectedCheck.id;
      objectIsEmpty = false;
    }

    // Create diffs comparing original values and current selection:
    // if (this.form.get('idContractors').dirty || this.bRemoved) {
    console.log('selectedContractors -> ' + JSON.stringify(this.selectedContractors));
    contractors = this.diffArrays(this.selectedContractors.slice(0), this.expedientDetails.id_contractors.slice(0));
    if (contractors.length > 0) {
      newExpedient.idContractors = contractors;
      objectIsEmpty = false;
    }
    // }

    // Areas. Diff and done:
    // if (this.form.get('idArea').dirty) {
    //   newExpedient.idArea = this.selectedArea.id;
    //   objectIsEmpty = false;
    // }
    console.log('sA -> ' + JSON.stringify(this.selectedAreas.slice(0)));
    console.log('id_area -> ' + JSON.stringify(this.expedientDetails.id_area.slice(0)));
    areas = this.diffArrays(this.selectedAreas.slice(0), this.expedientDetails.id_area.slice(0));
    console.log('areas diff -> ' + JSON.stringify(areas));
    if (areas.length > 0) {
      //Add contractorId:
      newExpedient.idArea = areas;
      objectIsEmpty = false;
    }

    // Always!! "Emails" can change due: Matchip removal, "Email" dirty, but also Linea de Negocios changes.
    // So just diff always and done:
    responsables = this.diffArraysResponsables(this.selectedEmails.slice(0), this.expedientDetails.emails.slice(0));
    if (responsables.length > 0) {
      //Add contractorId:
      newExpedient.responsables = responsables;
      objectIsEmpty = false;
    }

    //Hack: If any Matchip was removed force to go into.
    // if (this.form.get('contracts').dirty || this.form.get('code').dirty || this.bRemoved) {
    contracts = this.diffContracts(this.selectedContractList.slice(0), this.expedientDetails.id_contracts.slice(0));
    if (contracts.length > 0) {
      newExpedient.contracts = contracts;
      objectIsEmpty = false;
    }

    // }
    // UB depends on Business Line, and Mat-chips, and a pletora of cases.
    // So always diff ResponsableUB!
    rUb = this.diffArrays(this.selectedResponsableUb.slice(0), this.expedientDetails.id_responsables_ub.slice(0));
    if (rUb.length > 0) {
      newExpedient.idResponsablesUb = rUb;
      objectIsEmpty = false;
    }

    // if (this.form.get('idResponsablesUcc').dirty || this.bRemoved) {
    rUcc = this.diffArrays(this.selectedResponsableUcc.slice(0), this.expedientDetails.id_responsables_ucc.slice(0));
    if (rUcc.length > 0) {
      newExpedient.idResponsablesUcc = rUcc;
      objectIsEmpty = false;
    }

    // }
    // if (this.form.get('idResponsablesSpm').dirty || this.bRemoved) {
    rSpm = this.diffArrays(this.selectedResponsableSpm.slice(0), this.expedientDetails.id_responsables_spm.slice(0));
    if (rSpm.length > 0) {
      newExpedient.idResponsablesSpm = rSpm;
      objectIsEmpty = false;
    }
    // }
    newExpedient.action = 'Modified';

    // If value in the Form has changed or at least one Matchip was removed:
    if (!objectIsEmpty || this.bRemoved) {
      console.log(newExpedient);
      this.subscriptions.push(this._expedient.putExpedient(this.expedientDetails.id, newExpedient).subscribe(data => {
        console.log('DATA -> %o', data);
        this._util.goHome();

      }, response => {
        alert('Se ha producido un error -> ' + response);
      }, () => {
        console.log('The PUT observable is now completed.');
        this._util.goHome();

      }));
      setTimeout(() => this._util.goHome(), 1000); // HACKFIX!
    } else {
      alert('No hay cambios en el expediente');
    }
  }
  // Called by: onUpdateExpedient()
  // Usage: Recieves an array of the old contracts, and an array of the new/current contracts.
  // It ends returning an updated array marking the new ones with status="Added", and the removed ones as status="Deleted"
  // Unmodified are simply ignored:
  // Params:
  // fArray= ["foo", "foo2", "foo3"]; --> Final values in the Form
  // oArray= [{id: 101, name = "foo"}, {id: 104, name = "foo4"}]; --> Original (GETed) values
  // Return:
  // updatedAS = [{name = "foo2", status="Added"}, {name = "foo3", status="Added"}, {id="104" name = "foo4", status="Deleted"} ];
  private diffContracts(fArray: string[], oArray: ContractInterface[]) {

    // Return array to store Added and Deleted contracts:
    let updatedAS = [];
    // Let's remove duplicates, because shit happens:
    fArray.filter((item, index) => fArray.indexOf(item) !== index);
    oArray.filter((item, index) => oArray.indexOf(item) !== index);

    // New added contracts are the contracts in fArray which doesn't exist in oArray:
    let addedA = fArray.filter(str =>
      !oArray.some(obj => {
        return obj.name === str;
      })
    );
    // Add Status="Added" to all these Contracts:
    let addedAS = [];
    addedA.forEach(element => {
      let tempObj = {};
      // tslint:disable-next-line:no-string-literal
      tempObj['name'] = element;
      // tslint:disable-next-line:no-string-literal
      tempObj['status'] = 'Added';
      addedAS.push(tempObj);
    });

    // Deleted contracts are the contracts in oArray which doesn't exist in fArray:
    let deletedA = oArray.filter(obj =>
      !fArray.some(str => {
        return obj.name === str;
      })
    );

    // Add Status="Deleted" to deleted contracts:
    let deletedAS = deletedA.map(obj => ({ ...obj, status: 'Deleted' }));

    //Merge both arrays in just one:
    updatedAS = [...addedAS, ...deletedAS];
    return updatedAS;
  }
  // Called by: onUpdateExpedient()
  private diffArrays(newArray: any[], oldArray: any[]): any[] {
    // newArray has the current values of the Form
    // oldArray has the old values (returned in the Get)
    // Create a copy of new and old Arrays.
    let nArray = newArray.slice(0);
    let oArray = oldArray.slice(0);

    // Create an array to store Added and Deleted:
    let updatedArray = new Array();
    // Let's remove duplicates, because shit happens:
    nArray.filter((item, index) => nArray.indexOf(item) !== index);
    oArray.filter((item, index) => oArray.indexOf(item) !== index);

    // New added objects are the objects in nArray which doesn't exist in oArray:
    // let addedA = nArray.filter(x => !oArray.includes(x));
    let addedA = nArray.filter(x =>
      !oArray.some(el => {
        if ('id' in el) {
          return el.id === x.id;
        }
        if ('sid' in el) {
          return el.sid === x.sid;
        }
      })
    );
    //Add Status="Added" to all the objects:
    let addedAS = addedA.map(obj => ({ ...obj, status: 'Added' }));

    // Deleted objects are the objects in oArray which doesn't exist in nArray:
    let deletedA = oArray.filter(x =>
      !nArray.some(el => {
        if ('id' in el) {
          return el.id === x.id;
        }
        if ('sid' in el) {
          return el.sid === x.sid;
        }
      })
    );

    //Add Status="Deleted" to all the objects:
    let deletedAS = deletedA.map(obj => ({ ...obj, status: 'Deleted' }));

    //Merge both arrays in just one:
    let updatedAS = [...addedAS, ...deletedAS];
    return updatedAS;
  }
  private diffArraysResponsables(newArray: any[], oldArray: any[]): any[] {

    // newArray has the current values of the Form
    // oldArray has the old values (returned in the Get)
    // Create a copy of new and old Arrays.
    let nArray = newArray.slice(0);
    let oArray = oldArray.slice(0);

    // Let's remove duplicates, because shit happens:
    nArray.filter((item, index) => nArray.indexOf(item) !== index);
    oArray.filter((item, index) => oArray.indexOf(item) !== index);

    //If contractors have changed!:
    if (this.selectedContractors[0].sid != this.expedientDetails.id_contractors[0].sid) {
      let deletedArray = oldArray.map(obj => ({ ...obj, status: 'Deleted', contractorSid: this.expedientDetails.id_contractors[0].sid }));
      let addedArray = newArray.map(obj => ({ ...obj, status: 'Added', contractorSid: this.selectedContractors[0].sid }));
      return [...deletedArray, ...addedArray];
    }
    else {
      // New added objects are the objects in nArray which doesn't exist in oArray:
      // let addedA = nArray.filter(x => !oArray.includes(x));
      let addedA = nArray.filter(x =>
        !oArray.some(el => {
          if ('id' in el) {
            return el.id === x.id;
          }
          if ('sid' in el) {
            return el.sid === x.sid;
          }
        })
      );
      //Add Status="Added" to all the objects:
      let addedAS = addedA.map(obj => ({ ...obj, status: 'Added' }));
      //If "Added" identify the Selected contractor:
      addedAS = addedAS.map(obj => ({ ...obj, contractorSid: this.selectedContractors[0].sid }));

      // Deleted objects are the objects in oArray which doesn't exist in nArray:
      let deletedA = oArray.filter(x =>
        !nArray.some(el => {
          if ('id' in el) {
            return el.id === x.id;
          }
          if ('sid' in el) {
            return el.sid === x.sid;
          }
        })
      );

      //Add Status="Deleted" to all the objects:
      let deletedAS = deletedA.map(obj => ({ ...obj, status: 'Deleted', contractorSid: this.expedientDetails.id_contractors[0].sid }));
      //If Deleted retrieve the SID of the expedient:
      deletedAS = deletedAS.map(obj => ({ ...obj, contractorSid: this.expedientDetails.id_contractors[0].sid }));
      //Merge both arrays in just one:
      let updatedAS = [...addedAS, ...deletedAS];
      return updatedAS;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
