import { Component, OnInit, OnDestroy, Input, SimpleChanges } from '@angular/core';
import { ExpedientService } from 'src/app/services/expedient/expedient.service';
import { ExpedientInterface } from 'src/app/interfaces/expedient.interface';
import { Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { Status } from '../../models/status';
import { ExpedienteListInterface } from '../../interfaces/expedient-list-response.interface'
import { Subscription, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language/language.service';
import { debounceTime } from 'rxjs/operators';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';

@Component({
  selector: 'app-expedient-list',
  templateUrl: './expedient-list.component.html',
  styleUrls: ['./expedient-list.component.scss']
})
export class ExpedientListComponent implements OnInit, OnDestroy {
  //  @Input() lang: any;
  config: any;
  p = 1;

  private subscriptions: Subscription[] = [];
  public selected = 'all';
  public statusForm: FormGroup;
  public statusOptions: Status[];
  public expedientList: ExpedientInterface[];
  public limit = 5;
  public totalItems: number;
  public onChanges = new Subject<SimpleChanges>();
  private isUcc: boolean;
  private seeAllExpedients: boolean;

  constructor(
    private router: Router,
    private _expedient: ExpedientService,
    private translate: TranslateService,
    public formBuilder: FormBuilder,
    private _perms: PermissionsService,
  ) {
    this.statusForm = new FormGroup({
      status: new FormControl(),
      expCode: new FormControl(),
      cifOrName: new FormControl(),
      seeAll: new FormControl()
    });

    this.isUcc = false;
    this.seeAllExpedients = false;

    this.translateStatus();
  }

  ngOnInit() {
    this.subscriptions.push(this._perms.getPermissions().subscribe((user) => {
      this.isUcc = user.isUcc;
    }));

    this.loadList();
    this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
      this.translateStatus();
    }));
  }

  // jalapont - DS2-T14880: 
  // 1. Añadir filtrado por 'Código de expediente'
  // 2. Refactoring de loadList() para simplificar la consulta de expedientes 
  loadList() {
    this.translateStatus();
    //Initialize the Selector to "Todos":
    this.statusForm.controls['status'].setValue(this._expedient.getFilterActionValue() ? this._expedient.getFilterActionValue() : "all");
    this.statusForm.controls['expCode'].setValue(this._expedient.getSearchByExpCode() ? this._expedient.getSearchByExpCode() : '');
    this.statusForm.controls['cifOrName'].setValue(this._expedient.getSearchByCifValue() ? this._expedient.getSearchByCifValue() : '');
    this.statusForm.controls['seeAll'].setValue(this.seeAllExpedients);

    this.getAllExpedients();
    // Subscription to changeValues in form
    this.subscriptions.push(this.statusForm.valueChanges.pipe(debounceTime(800)).subscribe(
      (value) => {
        console.log("value: " + JSON.stringify(value));
        let state = value.status;
        let expCode = value.expCode;
        let cifOrName = value.cifOrName;

        this.subscriptions.push(this._expedient.getExpedientsFiltered(state, this.seeAllExpedients, expCode, cifOrName).subscribe(
          (expedientsReceived: ExpedienteListInterface) => {
            this.expedientList = expedientsReceived.expedients;
            this.totalItems = expedientsReceived.count;
          })); 
      }
    ));
  }
  getAllExpedients(limit?: any, page?: any) {
    if (this._expedient.getFilterActionValue() || this._expedient.getSearchByCifValue()) {
      this.subscriptions.push(this._expedient.getExpedientsFiltered(this._expedient.getFilterActionValue(), this.seeAllExpedients, this._expedient.getSearchByExpCode(), this._expedient.getSearchByCifValue()).subscribe(
        (expedientsReceived: ExpedienteListInterface) => {
          this.expedientList = expedientsReceived.expedients;
          this.totalItems = expedientsReceived.count;
        }));
    } else {
      this.subscriptions.push(this._expedient.getExpedientsFiltered('all', this.seeAllExpedients).subscribe((expedientsReceived: ExpedienteListInterface) => {
        this.expedientList = expedientsReceived.expedients;
        this.totalItems = expedientsReceived.count;
      }));
    }
  }

  goExpedientDetails() {
    this.router.navigate(['/expedient-details']);
  }

  translateStatus() {
    this.subscriptions.push(this.translate.get(['status']).subscribe(translations => {
      this.statusOptions = [
        new Status(translations.status.all, "all"),
        new Status(translations.status.created, "created"),
        new Status(translations.status.modified, "modified"),
        new Status(translations.status.approved, "approved"),
        new Status(translations.status.denied, "denied"),
        new Status(translations.status.rejected, "rejected"),
        new Status(translations.status.error, "error")
      ];
    }));
  }

  changeSeeAllExpedients() {
    this.statusForm.controls['seeAll'].setValue(!this.seeAllExpedients);
    this.seeAllExpedients = !this.seeAllExpedients;
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
