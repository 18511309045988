import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ExpedientService } from 'src/app/services/expedient/expedient.service';
import { HistoricalExpedientInterface } from 'src/app/interfaces/historicalExpedient.interface';
import { ExpedientDetailsInterface } from 'src/app/interfaces/expedientDetails.interface';
import { environment } from 'src/environments/environment';
import { runtimeEnvironment } from 'src/environments/runtimeEnvironment';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-history-list',
  templateUrl: './history-list.component.html',
  styleUrls: ['./history-list.component.scss']
})
export class historyListComponent implements OnInit, OnDestroy {
  private idExp: string;
  private subscriptions: Subscription[] = [];
  public historicalResponse: HistoricalExpedientInterface;
  public owner: string;
  public getHistoriesApi: string;
  public currentUrl: string;
  @Input() expedientDetails: ExpedientDetailsInterface;

  constructor(private _expedient: ExpedientService, private route: ActivatedRoute) {

  }

  ngOnInit() {
    let url = window.location.pathname;
    this.currentUrl = url;
    this.idExp = url.substr(url.lastIndexOf('/') + 1);

    this.getHistoriesApi = runtimeEnvironment.test ? `${runtimeEnvironment.test}/expedient/${this.idExp}/histories` : `${environment.apiUrl}/expedient/${this.idExp}/histories`;
    
    //  [this.route.snapshot['_routerState'].url].length - 1;
    if (this.expedientDetails.id) {
      this.getExpedientHistorical(this.expedientDetails.id);
    }
  }

  getExpedientHistorical(idExpedient: number) {
    this.subscriptions.push(this._expedient.getHistorical(idExpedient).subscribe(historicalReceived => {
      this.historicalResponse = historicalReceived;
      this.owner = this.historicalResponse.histories[0].username;
    }, err => {
      this._expedient.getHistoricalTemporal();
    }));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
