/* jalapont: RMS #5400 - modificado para que contenga los campos de Alta de empresa */
export class PostContractorDto {
    public type: string;
    public code: string;
    public company: string;
    public legalForm: string;

   constructor(type: string, cod: string, com: string, legalForm: string) {
       this.type = type; // Modificado para añadir la clase de documento
       this.code = cod;
       this.company = com;
       this.legalForm = legalForm;
    }
}