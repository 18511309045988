import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { SessionService } from 'src/app/services/session/session.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  pager: number;
  pageOfItems: number;
  numberOfPages = [];
  private token: string;

  @Input() currentUrl;
  @Input() limit;
  @Input() apiUrl;
  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private _session: SessionService
  ) { }

  ngOnInit() {
    // load page based on 'page' query param or default to 1
    this.subscriptions.push(this.route.queryParams.subscribe(x => {
      if(x['page']) {
        this.pageOfItems = parseInt(x['page'], 10);
      } else {
        this.pageOfItems = 1;
      }
      this.loadPage(this.limit, this.apiUrl);
    }));
    this.subscriptions.push(this._session.getTokenSubj$().subscribe((token: string) => {
      this.token = token;
    }));
  }

  next() {
    const newPage = this.pageOfItems + 1;
    return newPage.toString();
  }

  private loadPage(limit, apiUrl, page?) {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    // get page of items from api
    if (!page) {
      this.subscriptions.push(this.http.get<any>(`${apiUrl}?limit=${limit}`, { headers: reqHeader }).subscribe(x => {
        this.pager = x.pagination.totalPages;
        this.numberOfPages = [];

        let i = 1;
        while (i <= this.pager) {
          this.numberOfPages.push(i);
          i++;
        }
      }));
    } else {
      this.subscriptions.push(this.http.get<any>(`${apiUrl}?limit=${limit}&page=${page}`, { headers: reqHeader }).subscribe(x => {
        this.pager = x.pagination.totalPages;
        this.numberOfPages = [];
        console.log('Pager -> ' + this.pager + '\nPage Of Items -> ' + this.pageOfItems);
        let i = 1;
        while (i <= this.pager) {
          this.numberOfPages.push(i);
          i++;
        }
      }));
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

}
