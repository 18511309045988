import { Component, OnInit, Input } from '@angular/core';
import { OneHistoryInterface } from 'src/app/interfaces/one-history.interface';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-history-item',
  templateUrl: './history-item.component.html',
  styleUrls: ['./history-item.component.scss']
})
export class HistoryItemComponent implements OnInit {

  @Input() historyItem: OneHistoryInterface;
  public daysPassed: number;
  public statusTranslated: string;
  @Input() owner: string;
  private subscriptions: Subscription[] = [];

  private approved = '';
  private modified = '';
  private created = '';
  private denied = '';
  private rejected = '';
  private error = '';

  constructor(private translate: TranslateService) {
    this.subscriptions.push(this.translate.get(['statusExp']).subscribe(translations => {
      this.approved = translations.statusExp.approved;
      this.modified = translations.statusExp.modified;
      this.created = translations.statusExp.created;
      this.denied = translations.statusExp.denied;
      this.rejected = translations.statusExp.rejected;
      this.error = translations.statusExp.error;
    }));
  }

  ngOnInit() {
    this.subscriptions.push(this.translate.onLangChange.subscribe(() => {
      this.subscriptions.push(this.translate.get(['statusExp']).subscribe(translations => {
        this.approved = translations.statusExp.approved;
        this.modified = translations.statusExp.modified;
        this.created = translations.statusExp.created;
        this.denied = translations.statusExp.denied;
        this.rejected = translations.statusExp.rejected;
        this.error = translations.statusExp.error;
        this.daysPassed = this.calculateDays();
        this.statusTranslated = this.translateStatus(this.historyItem.action.toLowerCase());
        this.commentString();
      }));
    }));
    this.daysPassed = this.calculateDays();
    this.statusTranslated = this.translateStatus(this.historyItem.action.toLowerCase());
    this.commentString();
  }

  public calculateDays(): number {
    let date = new Date(this.historyItem.createdAt.substring(0, 10));
    let differenceInDays: number = Date.now() - date.getTime();
    differenceInDays = differenceInDays / (1000 * 3600 * 24);
    return Math.floor(differenceInDays);
  }

  public commentString() {
    return JSON.stringify(this.historyItem.comment);
  }

  public translateStatus(status: string) {
    switch (status.toLowerCase()) {
      case "approved":
        return this.approved;
      case "modified":
        return this.modified;
      case "created":
        return this.created;
      case "denied":
        return this.denied;
      case "rejected":
        return this.rejected;
      case "error":
          return this.error;
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
