import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AddEditExpedientComponent } from './components/add-edit-expedient/add-edit-expedient.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { RouterModule } from '@angular/router';
// angular material imports
import { MatButtonModule, MatCheckboxModule, MatRadioModule, MatSelectModule, MAT_DATE_LOCALE } from '@angular/material';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material';
import { MatDatepickerModule, MatNativeDateModule } from '@angular/material';
import { ExpedientOneItemComponent } from './components/expedient-one-item/expedient-one-item.component';
import { ExpedientListComponent } from './components/expedient-list/expedient-list.component';
import { HomeComponent } from './components/home/home.component';
import { MatDialogModule } from '@angular/material/dialog';
import { ExpedientDetailsComponent } from './components/expedient-details/expedient-details.component';
import { ExpedientHistoryComponent } from './components/expedient-history/expedient-history.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { ErrorComponent } from './components/error/error.component';
import { CookieService } from 'ngx-cookie-service';
import { NgxPaginationModule } from 'ngx-pagination';
// end of angular material imports
import { DetailViewExpedientComponent } from './components/detail-view-expedient/detail-view-expedient.component';
import { ApprovedRejectedExpedientComponent } from './dialogs/approved-rejected-expedient/approved-rejected-expedient.component';
import { HistoryItemComponent } from './components/history-item/history-item.component';

import { historyListComponent } from './components/history-list/history-list.component';
import { AddContractorDialogComponent } from './dialogs/add-contractor-dialog/add-contractor-dialog.component';
import { DeniedExpedientComponent } from './dialogs/denied-expedient/denied-expedient.component';
import { AddResponsableDialogComponent } from './dialogs/add-responsable-dialog/add-responsable-dialog.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { UnauthorizedComponent } from './components/unauthorized/unauthorized.component';
//end of angular material imports
import { LoaderComponent } from './loader/loader.component';
import { HttpErrorInterceptor } from '../app/interceptors/error-interceptor';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageComponent } from './components/language/language.component';
import { AuthGuard } from './guards/auth.guard';
import { LanguageService } from './services/language/language.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
@NgModule({
  declarations: [
    AppComponent,
    AddEditExpedientComponent,
    ExpedientOneItemComponent,
    ExpedientListComponent,
    HomeComponent,
    ExpedientDetailsComponent,
    ExpedientHistoryComponent,
    RedirectComponent,
    ErrorComponent,
    DetailViewExpedientComponent,
    ApprovedRejectedExpedientComponent,
    HistoryItemComponent,
    historyListComponent,
    AddContractorDialogComponent,
    DeniedExpedientComponent,
    AddResponsableDialogComponent,
    PaginationComponent,
    NavbarComponent,
    UnauthorizedComponent,
    LoaderComponent,
    LanguageComponent

  ],
  imports: [
    RouterModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // angular material modules
    BrowserAnimationsModule,
    MatButtonModule,
    MatRadioModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatCardModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatIconModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatNativeDateModule,
    MatDialogModule,
    MatSelectModule,
    // end angular material modules
    NgxPaginationModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })

  ],
  providers: [
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
    AuthGuard,
    LanguageService
  ],
  entryComponents: [
    ApprovedRejectedExpedientComponent,
    DeniedExpedientComponent,
    AddContractorDialogComponent,
    AddResponsableDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
