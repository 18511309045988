import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { ExpedientDetailsComponent } from './components/expedient-details/expedient-details.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { ErrorComponent } from './components/error/error.component';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'redirect', component: RedirectComponent },
  { path: 'expedient-details/:id', component: ExpedientDetailsComponent, canActivate: [AuthGuard] },
  { path: 'expedient-details', component: ExpedientDetailsComponent, canActivate: [AuthGuard] },
  { path: '**', component: ErrorComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
