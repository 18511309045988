import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ExpedientDetailsInterface } from 'src/app/interfaces/expedientDetails.interface';
import { ExpedientService } from 'src/app/services/expedient/expedient.service';
import { PermissionsService } from 'src/app/services/permissions/permissions.service';
import { MatDialog } from '@angular/material';
import { Subscription } from 'rxjs';
import { LegalFormService } from 'src/app/services/legalForm/legalForm.service';
import { LegalFormInterface } from 'src/app/interfaces/legalForm.interface';

@Component({
  selector: 'app-detail-view-expedient',
  templateUrl: './detail-view-expedient.component.html',
  styleUrls: ['./detail-view-expedient.component.scss']
})
export class DetailViewExpedientComponent implements OnInit, OnDestroy {

  @Input() expedientDetails: ExpedientDetailsInterface;
  private subscriptions: Subscription[] = [];
  public type: string;
  public bLoaded = false;
  public legalFormList: LegalFormInterface[] = [];
  public selectedLegalForm: string; 
  public showLegalForm: boolean;

  constructor(public dialog: MatDialog, private _expedient: ExpedientService, private _legalForm: LegalFormService
    ) { }

  ngOnInit() {
    // this.getTypeCheck(this.expedientDetails.id);
    this.bLoaded = true;
    console.log("legalform on ngOnInit: "+ this.expedientDetails.idLegalForm);
    if(this.expedientDetails.idLegalForm != null){
      this.initializeLegalForm(this.expedientDetails.idLegalForm);
      this.showLegalForm = true;
    }else{
      this.showLegalForm = false;
    }
    console.log("init - exp details: " + JSON.stringify(this.expedientDetails));
  }

  public getTypeCheck(idExpedient: number) {
    this.subscriptions.push(this._expedient.getTypeCheck(idExpedient).subscribe(typeCheck => {
      this.type = typeCheck.document + ': ' + typeCheck.name;
      setTimeout(() => this.bLoaded = true, 500);
    }));
  }

  /* jalapont: DS2-T14819 - Se inicializa la Forma Jurídica en el detalle del expediente */
  public initializeLegalForm(legalFormSid: number) {
    this.legalFormList = null;
    this.subscriptions.push(this._legalForm.getAllLegalForms('').subscribe(legalFormListReceived => {
      this.legalFormList = legalFormListReceived;
      console.log("initializeLegalForm: "+ JSON.stringify(this.legalFormList));
      this.legalFormList.forEach(legalForm => {
        if(legalForm.sid == legalFormSid){
          this.selectedLegalForm = legalForm.desc;
        }
      });      
    }));
  }


  ngOnDestroy() {
    // this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }
}
