import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { runtimeEnvironment } from 'src/environments/runtimeEnvironment';
import { SessionService } from '../session/session.service';
import { LegalFormInterface } from 'src/app/interfaces/legalForm.interface';

@Injectable({
  providedIn: 'root'
})

/* jalapont: RMS #5400 - Nuevo Service que conecta con el BACK para cargar Formas jurídicas */
export class LegalFormService {
  private lFormUrl: string;
  private token: string;

  constructor(public _http: HttpClient, private _session: SessionService) {
      this.lFormUrl = runtimeEnvironment.test 
          ? runtimeEnvironment.test + '/datamaster/legalForms' 
          : `${environment.apiUrl}/datamaster/legalForms`;

    this._session.getTokenSubj$().subscribe((usertoken: string) => {
      if (usertoken != null && usertoken != undefined && usertoken != '') {
        this.token = usertoken;
      }
    });
  }

  public getAllLegalForms(filterText: string): Observable<LegalFormInterface[]> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    let filter: string;
    let observable: Observable<LegalFormInterface[]>;
    if (filterText != undefined && filterText != '' && filterText != null) {
      filter = filterText.toString().trim().toLowerCase();
      observable = this._http.get<LegalFormInterface[]>(`${this.lFormUrl}?name=${filter}`, { headers: reqHeader });
    } else {
      observable = this._http.get<LegalFormInterface[]>(this.lFormUrl, { headers: reqHeader });
    }
    return observable;
  }
}