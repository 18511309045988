import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AreaInterface } from 'src/app/interfaces/area.interface';
import { environment } from 'src/environments/environment';
import { runtimeEnvironment } from 'src/environments/runtimeEnvironment';
import { SessionService } from '../session/session.service';

@Injectable({
  providedIn: 'root'
})
export class AreaService {

  private areaUrl: string;
  private token: string;

  constructor(public _http: HttpClient, private _session: SessionService) {
    this.areaUrl = runtimeEnvironment.test ? runtimeEnvironment.test + '/datamaster/divisions/' : `${environment.apiUrl}/datamaster/divisions/`;
    this._session.getTokenSubj$().subscribe((usertoken: string) => {
      if (usertoken) {
        this.token = usertoken;
      }
    });
  }

  public getAllAreas(idDivision: number, filterText: string): Observable<AreaInterface[]> {
    const reqHeader = new HttpHeaders({
      'Authorization': 'Bearer ' + this.token
    });
    let filter: string;
    let observable: Observable<AreaInterface[]>;
    if (filterText != undefined && filterText != '' && filterText != null && filterText.length > 2) {
      filter = filterText.toString().trim().toLowerCase();
      observable = this._http.get<AreaInterface[]>(`${this.areaUrl}${idDivision}/areas?name=${filter}`, { headers: reqHeader });
    } else {
      observable = this._http.get<AreaInterface[]>(`${this.areaUrl}${idDivision}/areas`, { headers: reqHeader });
    }
    return observable;
  }

}
