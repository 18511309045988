import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(private router: Router) { }

  goExpedientDetails() {
    this.router.navigate(['/expedient-details']);
  }
  goHome() {
    this.router.navigate(['/home']);
  }

  public handleLoadError(error?: any) {
    if (error) {
      console.log(error)
      if(error.status === 450) {
        this.router.navigate(['unauthorized']);
      }
    }
    this.router.navigate(['/']);
  }
}
