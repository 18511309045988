import {
    HttpEvent,
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpErrorResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SessionService } from '../services/session/session.service';
import { UtilService } from 'src/app/services/utils/util.service';
import { TranslateService } from '@ngx-translate/core';
import { ExpedientService } from '../services/expedient/expedient.service';

export class HttpErrorInterceptor implements HttpInterceptor {
    private subscriptions: Subscription[] = [];
    public action: string;
    private token: string;

    constructor(
        // tslint:disable-next-line:variable-name
        private _session: SessionService,
        private _util: UtilService,
        private router: Router,
        private translations: TranslateService,
        private _expedient: ExpedientService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            catchError((error: HttpErrorResponse) => {
                console.log('ERROR INTERCEPTOR');
                console.log(error.status);
                console.log(error);

                // DS2-T14820 - jalapont: solución para controlar errores del BACK 
                if(error.status >= 400){
                    this.handleErrors(error);
                }
                return next.handle(request);
            })
        );
    }

    /*  Comprueba y controla errores que devuelva el backend
    -------------------------------------------------------------------------
        httpError.body.error   --> contiene el código de error
        httpError.body.message --> contiene el error exacto que devuelve el WS 
    ------------------------------------------------------------------------- 
    */
    public handleErrors(error: HttpErrorResponse){
        var httpError = error.error;
        //console.log("handleErrors - http error: " + JSON.stringify(httpError));
        //console.log("httpError.body.error: " + httpError.body.error);
        //console.log("httpError.body.message: " + httpError.body.message);
        
        if(httpError.body.error === "APPROVE_EXP_ERROR"){
            console.log("estoy en APPROVE_EXP_ERROR");
            // Muestra un alert que contiene error exacto del WS
            // "No se ha podido aprobar el expediente. Contacte con el administrador" + error
            this.subscriptions.push(this.translations.get(['ManageErrors']).subscribe(translations => {
                window.alert(translations.ManageErrors.approveError 
                    + "\n\n" 
                    + "\"" + httpError.body.message + "\""
                    + "\n\n" 
                    + translations.ManageErrors.goHome);
            }));

            // Extraemos el ID de expediente de la URL de respuesta del error y lo convertimos a number
            // Nos quedamos con el número que está entre las cadenas /expedient/ y /action/ en la URL
            var urlError = error.url;
            var expId = urlError.substring(
                urlError.lastIndexOf("expedient/") + 10, 
                urlError.lastIndexOf("/action")
            );
            var idExpedient: number = +expId; 
            
            // DS2-T14820: Borramos el registro sobrante del histórico 
            // Se generan dos registros iguales y no se ha encontrado otra manera de controlarlo
            this.subscriptions.push(this._expedient.deleteLastError(idExpedient).subscribe(data => {}));
            console.log("ErrorInterceptor - deleteLastHistory - deleted last error registry");

            // Volvemos al listado con algo de margen (1 seg) para que dé tiempo a actualizar el estado del Expediente
            setTimeout(() => {
                this._util.goHome();
             }, 1000);
        }
        else if (httpError.body.error === "ERR_GENERIC"){ // Error por defecto que retorna al listado de expedientes
            console.log("estoy en DEFAULT");
            // Mensaje de error por defecto
            this.subscriptions.push(this.translations.get(['ManageErrors']).subscribe(translations => {
                window.alert(translations.ManageErrors.genericError 
                    + "\n\n" 
                    + translations.ManageErrors.goHome);
            }));
            // Volvemos al listado con algo de margen (1 seg) para que dé tiempo a actualizar el estado del Expediente
            setTimeout(() => {
                this._util.goHome();
            }, 1000);
        }
    }
}
