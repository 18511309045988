import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/services/language/language.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'expedient-app-front-end';
  constructor(public translate: TranslateService, private lang: LanguageService) {
    console.log("CultureLang: " +this.translate.getBrowserCultureLang());
    this.lang.setLang(this.parseLanguageForLiterals());
    // this language will be used as a fallback when a translation isn't found in the current language
    this.lang.setDefaultLang(this.lang.getLang());
    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.lang.useLang(this.lang.getLang());

  }

  // jalapont DS2-T14795: 
  // añadido para convertir el idioma detectado a algo conocido por nuestros assets i18n
  public parseLanguageForLiterals(){
    let browserLang = "";
    let cultureLang = this.translate.getBrowserCultureLang(); 
    if(cultureLang.includes("es")){
      browserLang = "es-ES";
    } else if(cultureLang.includes("en")){
      browserLang = "en-GB";
    } else if(cultureLang.includes("it")){
      browserLang = "it-IT";
    } else if(cultureLang.includes("pt")){
      browserLang = "pt";
    } else {
      // Se retorna idioma por defecto (Español) cuando el navegador está en un idioma desconocido
      browserLang = "es-ES";
    }
    console.log("ParsedLang: " +browserLang);
    return browserLang;
  }
}
